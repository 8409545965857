import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "company-watch" }
const _hoisted_2 = { class: "company-watch__header" }
const _hoisted_3 = { class: "company-watch__header-navigation" }
const _hoisted_4 = { class: "company-watch__left" }
const _hoisted_5 = { class: "company-watch__profile" }
const _hoisted_6 = { class: "company-watch__profile-body" }
const _hoisted_7 = { class: "company-watch__users" }
const _hoisted_8 = { class: "company-watch__users-header" }
const _hoisted_9 = { class: "company-watch__users-body" }
const _hoisted_10 = { class: "company-watch__right" }
const _hoisted_11 = { class: "company-watch__cameras" }
const _hoisted_12 = { class: "company-watch__cameras-header" }
const _hoisted_13 = { class: "company-watch__cameras-body" }
const _hoisted_14 = {
  key: 0,
  class: "options-block"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "options-block status" }
const _hoisted_17 = {
  key: 0,
  class: "loadingio-spinner-rolling-n528f37fyyp"
}
const _hoisted_18 = {
  key: 1,
  class: "error"
}
const _hoisted_19 = {
  key: 2,
  class: "success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_UserAdd = _resolveComponent("UserAdd")!
  const _component_UserDelete = _resolveComponent("UserDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.item.name), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.userRole === 'super' || _ctx.userRole === 'admin')
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              color: "primary",
              onClick: _ctx.editCompany
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Изменить ")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "company-watch__profile-header" }, [
          _createElementVNode("h3", null, "Профиль")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Название:", -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.item.name), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Дата добавления:", -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.item.created), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h3", null, "Пользователи: " + _toDisplayString(_ctx.getCompanyUsersListItems.length), 1),
          (_ctx.userRole === 'super' || _ctx.userRole === 'admin')
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                disable: _ctx.isCompanyUsersListBusy,
                color: "white",
                "text-color": "black",
                label: "Добавить",
                onClick: _ctx.addUser
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_q_table, {
            flat: "",
            style: {"height":"100%","overflow":"auto"},
            rows: _ctx.getCompanyUsersListItems,
            columns: _ctx.usersColumns,
            "row-key": "id",
            loading: _ctx.isCompanyUsersListBusy
          }, {
            "body-cell-action": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                    default: _withCtx(() => [
                      (_ctx.userRole === 'super' || _ctx.userRole === 'admin')
                        ? (_openBlock(), _createBlock(_component_q_item, {
                            key: 0,
                            clickable: "",
                            onClick: ($event: any) => (_ctx.actionRow('delete', props.value))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createElementVNode("svg", {
                                    fill: "#ff0000",
                                    version: "1.1",
                                    id: "Capa_1",
                                    width: "18px",
                                    height: "18px",
                                    viewBox: "0 0 482.428 482.429",
                                    "xml:space": "preserve",
                                    stroke: "#ff0000"
                                  }, [
                                    _createElementVNode("g", {
                                      id: "SVGRepo_bgCarrier",
                                      "stroke-width": "0"
                                    }),
                                    _createElementVNode("g", {
                                      id: "SVGRepo_tracerCarrier",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }),
                                    _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                      _createElementVNode("g", null, [
                                        _createElementVNode("g", null, [
                                          _createElementVNode("path", { d: "M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z" }),
                                          _createTextVNode(),
                                          _createElementVNode("path", { d: "M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z" }),
                                          _createTextVNode(),
                                          _createElementVNode("path", { d: "M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z" }),
                                          _createTextVNode(),
                                          _createElementVNode("path", { d: "M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z" })
                                        ])
                                      ])
                                    ])
                                  ], -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_item, {
                        clickable: "",
                        onClick: ($event: any) => (_ctx.actionRow('watch', props.value))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createElementVNode("svg", {
                                width: "18px",
                                height: "18px",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("g", {
                                  id: "SVGRepo_bgCarrier",
                                  "stroke-width": "0"
                                }),
                                _createElementVNode("g", {
                                  id: "SVGRepo_tracerCarrier",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }),
                                _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                  _createElementVNode("path", {
                                    d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                    stroke: "#000000",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ])
                              ], -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "no-data": _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
            ])),
            _: 1
          }, 8, ["rows", "columns", "loading"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("h3", null, "Камеры: " + _toDisplayString(_ctx.getCamerasListItems.length), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(), _createBlock(_component_q_table, {
            flat: "",
            rows: _ctx.getCamerasListItems,
            columns: _ctx.camerasColumns,
            "row-key": "id",
            key: _ctx.tableKey,
            loading: _ctx.isCamerasListBusy
          }, {
            "body-cell-name": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (props.row.name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("p", {
                          class: "button",
                          onClick: ($event: any) => (_ctx.watchCamera(props.row.id))
                        }, _toDisplayString(props.row.name), 9, _hoisted_15)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-status": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, [
                    (props.row.status === null)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[8] || (_cache[8] = [
                          _createElementVNode("div", { class: "ldio-17n7lgeict4" }, [
                            _createElementVNode("div")
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    (props.row.status === false)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_18, " error "))
                      : _createCommentVNode("", true),
                    (props.row.status === true)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_19, " success "))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "no-data": _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
            ])),
            _: 1
          }, 8, ["rows", "columns", "loading"]))
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActiveModal) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalAdd)
          ? (_openBlock(), _createBlock(_component_UserAdd, {
              key: 0,
              companyId: _ctx.selectedCompanyId,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["companyId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_UserDelete, {
              key: 1,
              userId: _ctx.selectedUserId,
              companyId: _ctx.selectedCompanyId,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["userId", "companyId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}