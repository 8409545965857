import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_separator),
      (!_ctx.isCompanyUsersListBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("p", null, "Вы действительно хотите удалить пользователя?", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "negative",
                  onClick: _ctx.actualDelete
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Удалить ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeDelete
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Отмена ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}