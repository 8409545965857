import Loading from 'quasar/src/plugins/loading/Loading.js';
import './styles/quasar.scss'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading,
  },
  lang: lang,
}
