import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "company-list" }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%"}
}
const _hoisted_4 = { class: "pre-header" }
const _hoisted_5 = { style: {"margin":"0"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"padding":"10px 10px 0 10px","height":"100%","box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, "Компании: " + _toDisplayString(_ctx.getCompanyListItems.length), 1)
              ]),
              (_ctx.userRole === 'super')
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    color: "primary",
                    disable: _ctx.isCompanyListBusy,
                    label: "Добавить",
                    icon: "mdi-plus",
                    onClick: _ctx.addCompany
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            (_openBlock(), _createBlock(_component_q_table, {
              flat: "",
              style: {"height":"100%"},
              rows: _ctx.getCompanyListItems,
              columns: _ctx.columns,
              "row-key": "id",
              key: _ctx.tableKey,
              loading: _ctx.isCompanyListBusy,
              onRequest: _ctx.onRequestCompany
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event)),
                  filled: "",
                  label: "ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedName) = $event)),
                  filled: "",
                  label: "Название"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.clearSelect
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.search
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              "body-cell-id": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-cameras-count": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "button",
                      onClick: ($event: any) => (_ctx.watchCamera(props.row.id))
                    }, _toDisplayString(props.value), 9, _hoisted_6)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-users-count": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "button",
                      onClick: ($event: any) => (_ctx.watchUsers(props.row.name))
                    }, _toDisplayString(props.value), 9, _hoisted_7)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-created": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                      default: _withCtx(() => [
                        (_ctx.userRole === 'super' || _ctx.userRole === 'admin')
                          ? (_openBlock(), _createBlock(_component_q_item, {
                              key: 0,
                              clickable: "",
                              onClick: ($event: any) => (_ctx.actionRow('edit', props.value))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, null, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createElementVNode("svg", {
                                      width: "18px",
                                      height: "18px",
                                      viewBox: "0 0 192 192",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xml:space": "preserve",
                                      fill: "none"
                                    }, [
                                      _createElementVNode("g", {
                                        id: "SVGRepo_bgCarrier",
                                        "stroke-width": "0"
                                      }),
                                      _createElementVNode("g", {
                                        id: "SVGRepo_tracerCarrier",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }),
                                      _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                        _createElementVNode("path", {
                                          d: "m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67",
                                          class: "cls-1",
                                          style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-dasharray":"none","stroke-opacity":"1"},
                                          transform: "translate(-77.923 40.646)"
                                        }),
                                        _createElementVNode("path", {
                                          d: "m195.656 33.271-52.882 52.882",
                                          style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"5","stroke-dasharray":"none","stroke-opacity":"1"},
                                          transform: "translate(-77.923 40.646)"
                                        })
                                      ])
                                    ], -1)
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (_ctx.actionRow('watch', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                      stroke: "#000000",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "loading", "onRequest"]))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}