import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { CamerasApi } from '../services/api/listApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';

// Создаем объект
const camerasApi = new CamerasApi();

// Создаем состояние
class State {
    isCamerasListBusy: boolean = false;
    camerasListItems: unknown [];
    camerasListItem: unknown;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isCamerasListBusy(state): boolean {
        return state.isCamerasListBusy;
    },

    getCamerasListItems(state): unknown[] {
        return state.camerasListItems;
    },

    getCamerasListItem(state): unknown {
        return state.camerasListItem;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsCamerasListBusy(state, payload: boolean) {
        state.isCamerasListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setCamerasListItems(state, payload: unknown[]) {
        state.camerasListItems = payload
    },

    setCamerasListItem(state, payload: unknown) {
        state.camerasListItem = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setCamerasListItem({ commit }, data: any) {
        commit('setCamerasListItem', data);
    },

    // Список элементов
    getCamerasListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                camerasApi.getCamerasListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setCamerasListItems', items);
                            commit('setIsCamerasListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setCamerasListItems', []);
                            commit('setIsCamerasListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Создать элемент
    createCamerasItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            camerasApi.createCamerasItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Редактировать элемент
    updateCamerasItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            camerasApi.updateCamerasItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteCamerasListItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            camerasApi.deleteCamerasItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Получить элемент
    getCamerasListItem({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            camerasApi.getCamerasListItem(filter)
                .then(
                    (response:any) => {
                        commit('setCamerasListItem', response.getItemsList()[0]);
                        commit('setIsCamerasListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getItemCamera({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);


        return new Promise((resolve, reject) => {

            // Получение снимка
            camerasApi.getItemCamera(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    updatePicture({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);


        return new Promise((resolve, reject) => {

            // Получение снимка
            camerasApi.updatePicture(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    cameraSync({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCamerasListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            camerasApi.cameraSync(data)
                .then(
                    (response:any) => {
                        commit('setIsCamerasListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCamerasListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    camerasTypes(){
        return new Promise((resolve, reject) => {
            camerasApi.camerasTypes()
            .then(
                (response: any) => {
                    resolve(response)
                },
                (error) => {
                    reject(error)
                }
            )
        })
    }

};

const CameraListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default CameraListModule;