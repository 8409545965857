import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ChangeApi } from '../services/api/ChangeApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';

// Создаем объект
const changeApi = new ChangeApi();

// Создаем состояние
class State {
    isChangeListBusy: boolean = false;
    changeListItems: unknown [];
    changeListItem: unknown;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isChangeListBusy(state): boolean {
        return state.isChangeListBusy;
    },

    getChangeListItems(state): unknown[] {
        return state.changeListItems;
    },

    getChangeListItem(state): unknown {
        return state.changeListItem;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsChangeListBusy(state, payload: boolean) {
        state.isChangeListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setChangeListItems(state, payload: unknown[]) {
        state.changeListItems = payload
    },

    setChangeListItem(state, payload: unknown) {
        state.changeListItem = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setChangeListItem({ commit }, data: any) {
        commit('setChangeListItem', data);
    },

    // Список элементов
    getChangeListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsChangeListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                changeApi.getChangeListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setChangeListItems', items);
                            commit('setIsChangeListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setChangeListItems', []);
                            commit('setIsChangeListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    changeTypes(){
        return new Promise((resolve, reject) => {
            changeApi.changeTypes()
            .then(
                (response: any) => {
                    resolve(response)
                },
                (error: any) => {
                    reject(error)
                }
            )
        })
    },

    changeEvents(){
        return new Promise((resolve, reject) => {
            changeApi.changeEvents()
            .then(
                (response: any) => {
                    resolve(response)
                },
                (error: any) => {
                    reject(error)
                }
            )
        })
    }

};

const ChangeListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default ChangeListModule;