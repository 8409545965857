import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "detection-watch" }
const _hoisted_2 = { class: "detection-watch__navigation" }
const _hoisted_3 = { class: "detection-watch__info" }
const _hoisted_4 = { class: "detection-watch__info__body" }
const _hoisted_5 = { class: "detection-watch__objects" }
const _hoisted_6 = { class: "detection-watch__objects__body" }
const _hoisted_7 = { class: "detection-watch__body" }
const _hoisted_8 = { class: "detection-watch__body-screen" }
const _hoisted_9 = {
  key: 0,
  src: "https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif",
  alt: "loader"
}
const _hoisted_10 = {
  width: "900",
  height: "500",
  ref: "camera_canvas",
  id: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, "# " + _toDisplayString(_ctx.route.params.id), 1),
      _createVNode(_component_q_btn, {
        color: "primary",
        onClick: _ctx.toList
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Статистика ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "detection-watch__info__header" }, [
        _createElementVNode("h3", null, "Инфо")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Камера:", -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.detection.camera), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Дата снимка:", -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.detection.date), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Объекты:", -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.detection.object_count), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "detection-watch__objects__header" }, [
        _createElementVNode("h3", null, "Объекты")
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_q_table, {
          flat: "",
          rows: _ctx.getStatListItems,
          columns: _ctx.columns,
          "row-key": "id",
          onRequest: _ctx.onRequestStatistic
        }, {
          "body-cell-camera_name": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(props.value), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "no-data": _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
          ])),
          _: 1
        }, 8, ["rows", "columns", "onRequest"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.loader)
          ? (_openBlock(), _createElementBlock("img", _hoisted_9))
          : _createCommentVNode("", true),
        _createElementVNode("canvas", _hoisted_10, null, 512)
      ])
    ])
  ]))
}