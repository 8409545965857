import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-list" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "pre-header" }
const _hoisted_5 = { style: {"margin":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, "Сообщения: " + _toDisplayString(_ctx.getMessageListItems.length), 1)
              ])
            ]),
            _createVNode(_component_q_table, {
              flat: "",
              rows: _ctx.getMessageListItems,
              columns: _ctx.columns,
              "row-key": "id",
              onRequest: _ctx.onRequestMessage
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedCameraUuid,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCameraUuid) = $event)),
                  filled: "",
                  label: "UUID камеры"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedCameraName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCameraName) = $event)),
                  filled: "",
                  label: "Название камеры"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.clearSelect
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.search
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              "body-cell-camera": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-zone": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-date": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-busy": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-allowed": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "onRequest"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}