import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "model-list" }
const _hoisted_2 = { class: "model-list__pre-header" }
const _hoisted_3 = { style: {"margin":"0"} }
const _hoisted_4 = { class: "model-list__table" }
const _hoisted_5 = {
  key: 0,
  class: "options-block"
}
const _hoisted_6 = {
  key: 1,
  class: "options-block"
}
const _hoisted_7 = {
  key: 0,
  class: "options-block"
}
const _hoisted_8 = {
  key: 1,
  class: "options-block"
}
const _hoisted_9 = {
  key: 2,
  class: "options-block"
}
const _hoisted_10 = {
  key: 3,
  class: "options-block"
}
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_modelDelete = _resolveComponent("modelDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_3, "Модели: " + _toDisplayString(_ctx.getModelListItems.length), 1)
      ]),
      _createVNode(_component_q_btn, {
        color: "primary",
        icon: "mdi-plus",
        label: "Добавить",
        onClick: _ctx.addModel,
        style: {"width":"fit-content","margin":"0 0 16px auto"}
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_q_table, {
        style: {"box-shadow":"none"},
        columns: _ctx.modelColumn,
        rows: _ctx.getModelListItems,
        loading: _ctx.isModelListBusy,
        "row-key": "id",
        onRequest: _ctx.onRequestModel
      }, {
        "body-cell-address": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.grabber_address)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", null, _toDisplayString(props.row.grabber_address), 1)
                  ]))
                : _createCommentVNode("", true),
              (props.row.snapshot_address)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(props.row.snapshot_address), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-options": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.row.vendor)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Vendor", -1)),
                    _createElementVNode("p", null, _toDisplayString(props.row.vendor), 1)
                  ]))
                : _createCommentVNode("", true),
              (props.row.low_res_source)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Video source low", -1)),
                    _createElementVNode("p", null, _toDisplayString(props.row.low_res_source), 1)
                  ]))
                : _createCommentVNode("", true),
              (props.row.mid_res_source)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Video source middle", -1)),
                    _createElementVNode("p", null, _toDisplayString(props.row.mid_res_source), 1)
                  ]))
                : _createCommentVNode("", true),
              (props.row.hi_res_source)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Video source high", -1)),
                    _createElementVNode("p", null, _toDisplayString(props.row.hi_res_source), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-cameras_count": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "button",
                onClick: ($event: any) => (_ctx.watchCamera(props.row.id))
              }, _toDisplayString(props.value), 9, _hoisted_11)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-action": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item, {
                    clickable: "",
                    onClick: ($event: any) => (_ctx.actionRow('edit', props.value))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("svg", {
                            width: "18px",
                            height: "18px",
                            viewBox: "0 0 192 192",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xml:space": "preserve",
                            fill: "none"
                          }, [
                            _createElementVNode("g", {
                              id: "SVGRepo_bgCarrier",
                              "stroke-width": "0"
                            }),
                            _createElementVNode("g", {
                              id: "SVGRepo_tracerCarrier",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }),
                            _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                              _createElementVNode("path", {
                                d: "m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67",
                                class: "cls-1",
                                style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-dasharray":"none","stroke-opacity":"1"},
                                transform: "translate(-77.923 40.646)"
                              }),
                              _createElementVNode("path", {
                                d: "m195.656 33.271-52.882 52.882",
                                style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"5","stroke-dasharray":"none","stroke-opacity":"1"},
                                transform: "translate(-77.923 40.646)"
                              })
                            ])
                          ], -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_q_item, {
                    clickable: "",
                    onClick: ($event: any) => (_ctx.actionRow('delete', props.value))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("svg", {
                            fill: "#ff0000",
                            version: "1.1",
                            id: "Capa_1",
                            width: "18px",
                            height: "18px",
                            viewBox: "0 0 482.428 482.429",
                            "xml:space": "preserve",
                            stroke: "#ff0000"
                          }, [
                            _createElementVNode("g", {
                              id: "SVGRepo_bgCarrier",
                              "stroke-width": "0"
                            }),
                            _createElementVNode("g", {
                              id: "SVGRepo_tracerCarrier",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }),
                            _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                              _createElementVNode("g", null, [
                                _createElementVNode("g", null, [
                                  _createElementVNode("path", { d: "M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z" }),
                                  _createTextVNode(),
                                  _createElementVNode("path", { d: "M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z" }),
                                  _createTextVNode(),
                                  _createElementVNode("path", { d: "M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z" }),
                                  _createTextVNode(),
                                  _createElementVNode("path", { d: "M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z" })
                                ])
                              ])
                            ])
                          ], -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["columns", "rows", "loading", "onRequest"])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActiveModal) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_modelDelete, {
              key: 0,
              modelId: _ctx.selectedModelId,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["modelId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}