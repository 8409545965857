import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "statistic-list" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "pre-header" }
const _hoisted_5 = { style: {"margin":"0"} }

import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import store from "@/store";
import type { QTableColumn } from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'StatisticList',
  setup(__props) {


/*import store from '@/store'*/
const router = useRouter()

const columns = ref<QTableColumn[]>([
  {
    name: 'camera_name',
    label: 'Камера',
    field: 'camera_name',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'zone_name',
    label: 'Зона',
    field: 'zone_name',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'shot_created',
    label: 'Дата снимка',
    field: 'shot_created',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'occupied_count',
    label: 'Занято мест',
    field: 'occupied_count',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'allowed_count',
    label: 'Разрешено',
    field: 'allowed_count',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'detected_count',
    label: 'Объекты',
    field: 'detected_count',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'action',
    label: '',
    align: 'left',
    field: 'id',
    headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
    style: 'width: 40px'
  }
])
const selectedCameraUuid = ref()
const selectedCameraId = ref()
const selectedZoneUuid = ref()
const selectedZoneId = ref()
const statisticPagination = ref({
  sortBy: 'stat_shot_created',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const selectedDetectionId = ref()
// const isStatListBusy = computed(() => {
//   return store.getters.getIsStatListBusy
// })

const getStatListItems = computed(() => {
  const items = store.getters.getStatListItems
  let rows: any = []
  if (items !== undefined) {
    for (let item of items) {
      rows.push({
        id: item.getStatId(),
        camera_id: item.getStatCameraId(),
        camera_name: item.getStatCameraName(),
        zone_id: item.getStatZoneId(),
        zone_name: item.getStatZoneName(),
        shot_created: item.getStatShotCreated(),
        occupied_count: item.getStatOccupiedCount(),
        allowed_count: item.getStatAllowedCount(),
        detected_count: item.getStatDetectedCount()
      })
    }
  }
  return rows
})
const getCamerasNameList = computed(() => {
  const items = store.getters.getCamerasListItems

  let rows: any = []
  if (items !== undefined) {
    for (let camera of items) {
      rows.push({
        value: camera.getCameraId(),
        label: camera.getCameraName()
      })
    }
  }
  return rows
})

const getZoneNameList = computed(() => {
  const zones = store.getters.getZoneListItems

  let rows: any = []
  if (zones !== undefined) {
    for (let zone of zones) {
      rows.push({
        value: zone.getZoneId(),
        label: zone.getZoneName()
      })
    }
  }
  return rows
})

const watchDetection = () => {
  router.push('/statistic/' + selectedDetectionId.value)
}

const actionRow = (method: any, id: any) => {
  selectedDetectionId.value = id
  method === 'watch' ? watchDetection() : console.log('Неизвестный метод')
}

const onRequestStatistic = (props: any) => {
  console.log('onRequestStatistic props', props)
  statisticPagination.value = props.pagination
  loadStatisticListItems()
}

const getFilters = () => {
  let filter = []
  if (selectedCameraUuid.value > 0) {
    filter.push({
      field_name: 'stat_camera_id',
      operand: '=',
      value: selectedCameraUuid.value
    })
  }
  if (selectedCameraId.value > 0) {
    filter.push({
      field_name: 'stat_camera_id',
      operand: '=',
      value: selectedCameraId.value
    })
  }
  if (selectedZoneUuid.value > 0) {
    filter.push({
      field_name: 'stat_zone_id',
      operand: '=',
      value: selectedZoneUuid.value
    })
  }
  if (selectedZoneId.value > 0) {
    filter.push({
      field_name: 'stat_zone_id',
      operand: '=',
      value: selectedZoneId.value
    })
  }

  else {
    filter.push({
      field_name: 'stat_camera_id',
      operand: '>',
      value: 0
    })
  }
  return filter
}

const loadStatisticListItems = () => {
  store
    .dispatch('getStatListItems', {
      filter: getFilters(),
      sort: {
        name: 'stat_shot_created',
        exp: 'desc',
      },
      pagination: {
        page: statisticPagination.value.page,
        limit: statisticPagination.value.rowsPerPage,
        pages: 0,
        cnt: statisticPagination.value.rowsNumber,
      }
    })
    .then(
      (resolve) => {
        console.log('getStatListItems resolve', resolve)
        statisticPagination.value.rowsNumber = resolve.pagination.getPages()
      },
      (reject) => {
        console.log('getStatListItems reject', reject)
      }
    )
}

const search = () => {
  loadStatisticListItems()
}

const clearSelect = () => {
  selectedCameraUuid.value = null
  selectedCameraId.value = ''
  selectedZoneUuid.value = null
  selectedZoneId.value = ''
  loadStatisticListItems()
}

const loadCamerasList = () => {
  store.dispatch('getCamerasListItems', {})
    .then(
      (resolve) => {
        console.log('getCamerasListItems resolve', resolve)
      },
      (reject) => {
        console.log('getCamerasListItems reject', reject)
      }
    )
}


const loadZoneList = () => {
  store.dispatch('getZoneListItems', {})
    .then(
      (res) => {
        console.log('zoneList success', res)
      },
      (rej) => {
        console.log('zoneList error', rej)
      }
    )
}

loadZoneList()
loadCamerasList()
loadStatisticListItems()


return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, "Статистика парковки: " + _toDisplayString(getStatListItems.value.length), 1)
            ]),
            _createVNode(_component_q_table, {
              flat: "",
              rows: getStatListItems.value,
              columns: columns.value,
              "row-key": "id",
              onRequest: onRequestStatistic,
              pagination: statisticPagination.value,
              "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((statisticPagination).value = $event))
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: selectedCameraUuid.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCameraUuid).value = $event)),
                  filled: "",
                  label: "UUID камеры"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_select, {
                  modelValue: selectedCameraId.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedCameraId).value = $event)),
                  options: getCamerasNameList.value,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Название камеры"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: selectedZoneUuid.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedZoneUuid).value = $event)),
                  filled: "",
                  label: "ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_select, {
                  modelValue: selectedZoneId.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedZoneId).value = $event)),
                  options: getZoneNameList.value,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Название зоны"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: clearSelect
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: search
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                })
              ]),
              "body-cell-camera_name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-zone_name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-shot_created": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-occupied_count": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-allowed_count": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('watch', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                      stroke: "#000000",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "pagination"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})