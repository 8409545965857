import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md row wrap justify-center items-center content-center" }
const _hoisted_2 = {
  id: "errMessage",
  class: "text-negative"
}
const _hoisted_3 = { class: "full-width row wrap justify-center items-center content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-h6 col-12 text-center q-pa-md" }, "Вход в систему", -1)),
    _createVNode(_component_q_card, { class: "q-pa-md col-xs-12 col-sm-6 col-lg-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_form, {
          onSubmit: _ctx.onSubmit,
          onReset: _ctx.onReset,
          class: "q-gutter-md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              filled: "",
              modelValue: _ctx.login,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
              label: "Логин",
              "lazy-rules": "",
              rules: [(val) => (val && val.length > 0) || 'Неправильный логин']
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: _ctx.passw,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passw) = $event)),
              filled: "",
              type: _ctx.isPwd ? 'password' : 'text',
              label: "Пароль"
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: _ctx.isPwd ? 'visibility_off' : 'visibility',
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPwd = !_ctx.isPwd))
                }, null, 8, ["name"])
              ]),
              _: 1
            }, 8, ["modelValue", "type"]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errMessage), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_btn, {
                label: "Войти",
                type: "submit",
                color: "primary",
                "no-caps": "",
                unelevated: ""
              }),
              _createVNode(_component_q_btn, {
                label: "Сброс",
                type: "reset",
                color: "primary",
                flat: "",
                class: "q-ml-sm",
                "no-caps": "",
                unelevated: ""
              })
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "onReset"])
      ]),
      _: 1
    })
  ]))
}