<template>
  <div class="services-list">
    <div class="services-list__pre-header">
      <h3>Сервисы: {{ getServiceListItems.length }}</h3>
        <q-btn color="primary" icon="mdi-plus" label="Добавить" @click="addService"/>
    </div>
    <div class="services-list__table">
      <q-table style="box-shadow: none"
          :columns="serviceColumn"
          :rows="getServiceListItems"
          row-key="id"
          @request="onRequestService"
      >
        <template v-slot:body-cell-cameras_count="props">
          <q-td :props="props">
            <span class="button" @click="watchCamera(props.row.id)">{{ props.value }}</span>
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-list style="display: flex">
              <q-item clickable @click="actionRow('edit', props.value)">
                <q-item-section>
                  <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg"
                       xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path>
                      <path d="m195.656 33.271-52.882 52.882"
                            style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1"
                            transform="translate(-77.923 40.646)"></path></g></svg>
                </q-item-section>
              </q-item>
              <q-item clickable @click="actionRow('delete', props.value)">
                <q-item-section>
                  <svg fill="#ff0000" version="1.1" id="Capa_1" width="18px" height="18px" viewBox="0 0 482.428 482.429" xml:space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path> <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path> <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path> </g> </g> </g></svg>
                </q-item-section>
              </q-item>
            </q-list>
          </q-td>
        </template>

      </q-table>
    </div>
    <q-dialog v-model="isActiveModal">
      <serviceDelete
          v-if="isActiveModalDelete"
          :serviceId="selectedServiceId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>



</template>


<script lang="ts">


import store from '@/store'
import {defineComponent} from 'vue'
import serviceDelete from './ServiceDelete.vue'
import { useRouter } from 'vue-router'

export default defineComponent({

  components: {
    serviceDelete
  },


  name: "ServicesList",

  data(){
    return{
      router: useRouter(),
      item: {
        id: 0,
        name: '',
        comment: '',
        cameras_count: 0
      },
      serviceColumn: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'comment',
          label: 'Примечание',
          field: 'comment',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'cameras_count',
          label: 'Камеры',
          field: 'cameras_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: '',
          field: 'id',
          align: 'left',
          style: 'width: 18px; padding: 0 15px 15px 15px !important;'
        }
      ] as any,
      servicePagination: {
        sortBy: 'service_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      isActiveModalDelete: false,
      isActiveModal: false,
      selectedServiceId: null as any
    }
  },

  computed: {
    isServiceListBusy() {
      return store.getters.getIsServiceListBusy
    },

    //Формирование списка сервисов
    getServiceListItems() {
      const items = store.getters.getServiceListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getServiceId(),
            name: item.getServiceName(),
            comment: item.getServiceComment(),
            cameras_count: item.getServiceCamerasCount()
          })
        }
      }
      return rows
    },

  },

  methods: {

    //Просмотр камеры
    watchCamera(id: any){
      this.router.push('/cameras?service_id=' + id)
    },

    //Закрытие модалок
    hideModal(){
      this.isActiveModal = false
      this.isActiveModalDelete = false
      this.selectedServiceId = null
    },

    //Изменеие сервиса
    editService(){
      this.router.push('/services/edit/' + this.selectedServiceId)
    },

    //Добавление сервиса
    addService(){
      this.router.push('/services/add')
    },

    //Удаление сервиса
    deleteService(){
      this.isActiveModalDelete = true
      console.log('deleteService')
      this.isActiveModal = true
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedServiceId = id
      method === 'delete' ? this.deleteService() :
          method === 'edit' ? this.editService() : console.log('Неизвестный метод')
    },

    //Подгрузка после взаимодействий
    afterAction() {
      console.log('afterAction')
      this.loadServiceListItems()
      this.hideModal()
    },

    //Запрос к таблице сервисов
    onRequestService(props: any) {
      console.log('onRequestService props', props)
      this.servicePagination = props.pagination
      this.loadServiceListItems()
    },

    //Фильтры сервисов
    getFilters() {
      const link = new URL(window.location.href).searchParams.get('service_id')
      if(link){
        return [
          {
            field_name: 'service_id',
            operand: '=',
            value: link,
          }
        ]
      } else{
        return [
          {
            field_name: 'service_name',
            operand: '>',
            value: 0,
          }
        ]
      }

    },

    //Загрузка списка сервисов
    loadServiceListItems() {

      store
          .dispatch('getServiceListItems', {
            filter: this.getFilters(),
            sort: {
              name: this.servicePagination.sortBy,
              exp: this.servicePagination.descending ? 'DESC' : 'ASC',
            },
          })
          .then(
              (resolve) => {
                console.log('getServiceListItems resolve', resolve)
                this.servicePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getServiceListItems reject', reject)
              }
          )
    },

  },

  created(){
    this.loadServiceListItems()
  }

})
</script>

<style lang="scss">

.services-list{
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__pre-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
      margin: 0;
    }
    .q-btn{
      padding: 12px 24px 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }

  .q-table__middle{
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
    .button{
      color: #027BE3;
      cursor: pointer;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  &__table{
    td{
      padding: 15px !important;
      vertical-align: top;
    }
    .options-block{
      margin: 0 0 15px 0;
      h3{
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 100%;
      }
      p{
        padding: 0;
        margin: 0;
        font-size: 13px;
        line-height: 100%;
      }
    }
  }

}

</style>