import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'Breadcrumbs',
  setup(__props) {

const route = useRoute()

const breadCrumbs = computed(() => {
  if (typeof route.meta.breadCrumb === 'function') {
    return route.meta.breadCrumb.call(this, route)
  }
  const currentPath = route.path.split('/')
  const breadCrumb: any = route.meta.breadCrumb
  if(!isNaN(+currentPath[currentPath.length - 1]) && breadCrumb && breadCrumb[breadCrumb.length - 1].text !== 'Главная'){
    breadCrumb[breadCrumb.length - 1].text = breadCrumb[breadCrumb.length - 1].text.replace(/[0-9№]/g, '') + ' №' + +currentPath[currentPath.length - 1]
  }
  return breadCrumb

})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_breadcrumbs_el = _resolveComponent("q-breadcrumbs-el")!
  const _component_q_breadcrumbs = _resolveComponent("q-breadcrumbs")!

  return (_openBlock(), _createBlock(_component_q_breadcrumbs, {
    class: "row q-px-md q-pt-md text-grey-10 q-breadcrumbs",
    "active-color": "grey-7"
  }, {
    separator: _withCtx(() => [
      _createVNode(_component_q_icon, {
        size: "1.5em",
        name: "chevron_right",
        color: "grey-5"
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadCrumbs.value, (item) => {
        return (_openBlock(), _createBlock(_component_q_breadcrumbs_el, {
          key: item,
          to: item.to,
          label: item.text,
          icon: item.icon,
          replace: ""
        }, null, 8, ["to", "label", "icon"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})