import script from "./StatisticList.vue?vue&type=script&setup=true&lang=ts"
export * from "./StatisticList.vue?vue&type=script&setup=true&lang=ts"

import "./StatisticList.vue?vue&type=style&index=0&id=8ca84034&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QTable,QInput,QSelect,QBtn,QTd,QList,QItem,QItemSection,QField});
