<template>

  <div class="detection-watch">
    <div class="detection-watch__navigation">
      <h2># {{ route.params.id }}</h2>
      <q-btn color="primary" @click="toList">
        Статистика
      </q-btn>
    </div>
    <div class="detection-watch__info">
      <div class="detection-watch__info__header">
        <h3>Инфо</h3>
      </div>
      <div class="detection-watch__info__body">
        <ul>
          <li>
            <h3>Камера:</h3>
            <p>{{ detection.camera }}</p>
          </li>
          <li>
            <h3>Дата снимка:</h3>
            <p>{{ detection.date }}</p>
          </li>
          <li>
            <h3>Объекты:</h3>
            <p>{{ detection.object_count }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="detection-watch__objects">
      <div class="detection-watch__objects__header">
        <h3>Объекты</h3>
      </div>
      <div class="detection-watch__objects__body">
        <q-table
            flat
            :rows="getStatListItems"
            :columns="columns"
            row-key="id"
            @request="onRequestStatistic"
        >
          <template v-slot:body-cell-camera_name="props">
            <q-td :props="props">
              <span>{{ props.value }}</span>
            </q-td>
          </template>
          <template v-slot:no-data>
            <div class="col-12 text-center">Нет данных</div>
          </template>
        </q-table>
      </div>
    </div>
    <div class="detection-watch__body">
      <div class="detection-watch__body-screen">
        <img v-if="loader" src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="loader">
        <canvas width="900" height="500" ref="camera_canvas" id='canvas'></canvas>
      </div>
    </div>

  </div>

</template>

<script lang="ts">

import {defineComponent} from "vue";
import store from "@/store";
import { useRouter, useRoute } from 'vue-router'
/*import store from '@/store'*/

export default defineComponent({
  name: "DetectionWatch",

  data(){
    return{
      router: useRouter(),
      route: useRoute(),
      item: {
        id: 0,
        picture_key: '',
        picture_url: ''
      },
      loader: true,
      points: [[]] as any,
      zoneType: [false] as any,
      detection: {
        camera_id: '' as any,
        camera: '' as any,
        date: '' as any,
        object_count: null as any
      },
      zonePagination: {
        sortBy: 'zone_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      columns: [
        {
          name: 'zone_name',
          label: 'Название зоны',
          field: 'zone_name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'shot_created',
          label: 'Дата снимка',
          field: 'shot_created',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'free_place',
          label: 'Свободно мест',
          field: 'free_place',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }/*,
        {
          name: 'detected_count',
          label: 'Количество детекций',
          field: 'detected_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }*/
      ] as any,
      detectionSwitch: true,
      statisticPagination: {
        sortBy: 'stat_shot_created',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
    }
  },

  computed: {

    isStatListBusy() {
      return store.getters.getIsStatListBusy
    },

    //Формирование списка статистики
    getStatListItems() {
      const items = store.getters.getStatListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            zone_name: item.getStatZoneName(),
            shot_created: item.getStatShotCreated(),
            free_place: item.getStatAllowedCount() - item.getStatOccupiedCount(),
            /*detected_count: item.getStatDetectedCount()*/
          })
        }
      }
      return rows
    },

    //Получение списка зон для орисовки
    getZonePoint(){
      const items = store.getters.getZoneListItems
      let points: any = []
      if (items !== undefined) {
        for (let zone of items) {
          points.push({
            points: zone.getZoneSpot(),
            type: zone.getZoneIsAllowed(),
          })
        }
      }
      return points
    },

  },

  methods: {

    //Возвращение к списку статистики
    toList(){
      this.router.push('/statistic')
    },

    //Запрос к таблице с статистикой
    onRequestStatistic(props: any) {
      console.log(props)
      console.log('onRequestStatistic props', props)
      this.statisticPagination = props.pagination
      this.loadStatisticListItems(false)
    },

    //Получение списка статистики
    loadStatisticListItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.statisticPagination.page = 1
        this.statisticPagination.rowsNumber = 0
      }
      store
          .dispatch('getStatListItems', {
            filter: [
              {
                field_name: 'stat_camera_id',
                operand: '=',
                value: this.route.params.id,
              },
            ],
            sort: {
              name: this.statisticPagination.sortBy,
              exp: this.statisticPagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.statisticPagination.page,
              limit: this.statisticPagination.rowsPerPage,
              pages: 0,
              cnt: this.statisticPagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getStatListItems resolve', resolve)
                this.statisticPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getStatListItems reject', reject)
              }
          )
    },

    //Загрузка статистики по id
    loadData() {
      store
          .dispatch('getStatListItem', {
            filter: [
              {
                field_name: 'stat_id',
                operand: '=',
                value: this.route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.detection = {
                  camera_id: resolve.getStatCameraId(),
                  camera: resolve.getStatCameraName(),
                  date: resolve.getStatShotCreated(),
                  object_count: resolve.getStatOccupiedCount()
                }
                this.loadCamera()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка данных камеры
    loadCamera() {
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.detection.camera_id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCameraId(),
                  picture_key: resolve.getCameraPictureKey(),
                  picture_url: resolve.getCameraPictureUrl()
                }
                this.loadLastFrame()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Получение крайнего кадра с камеры
    loadLastFrame() {
      store
          .dispatch('getItemCamera', {id: this.item.id}).then(
          (resolve) => {
            this.drawing()
            this.loader = false
            console.log('loadLastFrame resolve', resolve)
          },
          (reject) => {
            this.drawError()
            this.loader = false
            console.log('loadLastFrame reject', reject)
          }
      )
    },

    //Загрузка списка зон
    loadZoneListItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.zonePagination.page = 1
        this.zonePagination.rowsNumber = 0
      }
      store
          .dispatch('getZoneListItems', {
            filter: [
              {
                field_name: 'zone_camera_id',
                operand: '=',
                value: this.route.params.id,
              },
            ],
            sort: {
              name: this.zonePagination.sortBy,
              exp: this.zonePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.zonePagination.page,
              limit: this.zonePagination.rowsPerPage,
              pages: 0,
              cnt: this.zonePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getZoneListItems resolve', resolve)
                this.zonePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getZoneListItems reject', reject)
              }
          )
    },

    //Отрисовка изображения с камеры и зон
    drawing() {

      setTimeout(() => {
        let zoneList = this.getZonePoint
        let points = [] as any
        let regexX = /"x":\d{1,3}/gm;
        let regexY = /"y":\d{1,3}/gm;
        zoneList.forEach( ( zone: any ) => {
          points.push(zone.points.split('},{'))
          this.zoneType.push(zone.type)
        })
        points.forEach(( pointList: any ) => {
          let setSpot = [] as any
          pointList.forEach( (point: any) => {
            let spotObj = {
              x: 0,
              y: 0
            }
            let spotX = point.match(regexX)
            let spotY = point.match(regexY)
            if(spotX !== null){
              spotX.forEach( (spot : any) => {
                spotObj.x = Number(spot.replace('"x":',''))
              })
            }
            if(spotY !== null){
              spotY.forEach( (spot : any) => {
                spotObj.y = Number(spot.replace('"y":',''))
              })
            }
            setSpot.push(spotObj)
          })
          this.points.push(setSpot)

        })
      }, 100)

      setTimeout( () => {

        let canvas: any = document.getElementById("canvas");
        canvas.width = 900;
        canvas.height = 500;
        let ctx = canvas.getContext("2d");
        let cameraImage = new Image();
        cameraImage.src = this.item.picture_url;
        let zoneType: any = this.zoneType
        let points: any = this.points
        cameraImage.onload = function() {
          ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);

          points.forEach((point: any,idx: any) => {
            if(zoneType[idx]){
              ctx.fillStyle = 'rgba(0,232,39,0.3)';
              ctx.strokeStyle = 'rgba(0,232,39,0.3)';
            }else{
              ctx.fillStyle = 'rgba(232,0,0,0.3)';
              ctx.strokeStyle = 'rgba(232,0,0,0.3)';
            }
            let closed = true;
            ctx.beginPath();
            for (const p of point) {
              ctx.lineTo(p.x, p.y)
            }
            closed && ctx.closePath();
            ctx.stroke();
            closed && ctx.fill();


            for (const p of point) {
              ctx.beginPath();
              ctx.moveTo(p.x + 10, p.y);
              ctx.fill();
              ctx.stroke();
            }

          })
        }

      },150)

    },

    //Отрисовка ошибки
    drawError(){
      let cameraImage = new Image();
      cameraImage.src = 'https://mediapure.ru/wp-content/uploads/2017/12/error-1021x580.jpg';
      let canvas: any = document.getElementById("canvas");
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      cameraImage.onload = function() {
        ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
      }
    }

  },
  created(){
    this.loadData()
    this.loadStatisticListItems(true)
    this.loadZoneListItems(true)
  }
})

</script>

<style lang="scss">

.detection-watch{
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 30px 0;
    h2{
      font-weight: 500;
      margin: 0;
    }
    .q-btn{
      padding: 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }
  &__info,&__objects{
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    height: fit-content;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &__header {
      background: #3742fa;
      border-bottom: 1px solid #b3b3b3;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 20px;
      border-radius: 10px 10px 0 0;
      height: 77px;
      .q-toggle__inner--truthy{
        color: #fff !important;
      }
      h3{
        font-weight: 500;
      }
      h3, p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 100%;
        color: #fff;
      }
    }
  }
  &__info,&__objects{
    &__body {
      ul {
        padding: 18px 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
          display: flex;
          align-items: flex-start;
          list-style: none;

          &:not(:last-child), &:not(:first-child) {
            padding: 15px 0;
          }

          h3 {
            width: fit-content;
            text-align: right;
            flex-shrink: 0;
          }

          h3, p {
            font-size: 16px;
            font-weight: 700;
            padding: 0;
            margin: 0;
            line-height: 100%;
          }

          p {
            font-weight: 400;
            padding: 0 0 0 15px;
          }
        }
      }
    }
  }
  &__objects{
    &__header{
      display: flex;
      justify-content: space-between;
    }
    &__body{
      .no-data{
        padding: 18px 20px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        line-height: 100%;
      }
    }
  }
  &__body{
    width: 100%;
    margin: 20px 0 0 0;
    padding: 18px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &-screen{
      position: relative;
      img{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 30px;
        height: 30px;
      }
    }
    canvas{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}

</style>