import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"


import { useRouter, useRoute } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'CameraSyncError',
  props: ['isConnectionError'],
  setup(__props) {


const props = __props
const router = useRouter()
const route = useRoute()
const userRole = localStorage.getItem('userRole')

const watchDetection = () => {
  window.stop()
  router.push('/cameras/watch/detections/' + route.params.id)
}



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "sync-error" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Что-то пошло не так...", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "Возникла ошибка при попытке подключения к камере, пожалуйста повторите попытку позже", -1)),
          (props.isConnectionError)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/cameras')))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Назад")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(userRole) === 'super' || _unref(userRole) === 'admin')
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                onClick: watchDetection
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Настройка детекции")
                ])),
                _: 1
              }))
            : (!props.isConnectionError && _unref(userRole) !== 'super')
              ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, { key: 2 }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Закрыть")
                  ])),
                  _: 1
                })), [
                  [_directive_close_popup]
                ])
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})