import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cameras" }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%"}
}
const _hoisted_4 = { class: "pre-header" }
const _hoisted_5 = { style: {"margin":"0"} }
const _hoisted_6 = { class: "cameras__name" }
const _hoisted_7 = {
  key: 0,
  class: "options-block"
}
const _hoisted_8 = {
  key: 1,
  class: "options-block"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "options-block"
}
const _hoisted_12 = {
  key: 1,
  class: "options-block"
}
const _hoisted_13 = {
  key: 2,
  class: "options-block"
}
const _hoisted_14 = {
  key: 0,
  class: "options-block"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 1,
  class: "options-block"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 0,
  class: "options-block"
}

import { ref, computed } from 'vue'
import CameraEdit from './CameraEdit.vue'
import CameraAdd from './CameraAdd.vue'
import store from "@/store";
import { useRouter } from 'vue-router';
import type { QTableColumn } from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'CameraList',
  setup(__props) {

const router = useRouter();
const columns = ref<QTableColumn[]>([
  {
    name: 'id',
    label: 'ID',
    field: 'id',
    align: 'left',
    style: 'max-width: 50px',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'status',
    label: 'Статус',
    field: 'status',
    align: 'left',
    style: 'width: fit-content;max-width: 90px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'name_model',
    label: 'Название',
    field: 'name_model',
    align: 'left',
    style: 'max-width: 200px',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'address_mac_url',
    label: 'IP/MAC/URL',
    field: 'address_mac_url',
    align: 'left',
    style: 'max-width: 200px;text-overflow: ellipsis;overflow: hidden',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'company_service',
    label: 'Компания / Сервисы',
    field: 'company_service',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'login_password',
    label: 'Логин и Пароль',
    field: 'login_password',
    align: 'left',
    style: 'max-width: 200px',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'survey_period',
    label: 'Период опроса',
    field: 'survey_period',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'created',
    label: 'Добавлена',
    field: 'created',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'action',
    label: 'Действие',
    align: 'center',
    field: 'id',
    headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
    style: 'width: 40px'
  }
])
const cameraPagination = ref({
  sortBy: 'camera_id',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const modelPagination = ref({
  sortBy: 'model_id',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const companyPagination = ref({
  sortBy: 'company_id',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const servicePagination = ref({
  sortBy: 'service_id',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const selectedName = ref('')
const selectedId = ref()
const selectedModel = ref()
const selectedCompany = ref()
const selectedService = ref()
const isActiveModalEdit = ref(false)
const isActiveModalAdd = ref(false)
const isActiveModalDelete = ref(false)
const isActiveModal = ref(false)
const selectedCameraId = ref()

const isCamerasListBusy = computed(() => {
  return store.getters.getIsCamerasListBusy
})

const getCamerasListItems = computed(() => {
  const items = store.getters.getCamerasListItems
  let rows: any = []
  if (items !== undefined) {
    for (let camera of items) {
      rows.push({
        id: camera.getCameraId(),
        name: camera.getCameraName(),
        status: camera.getCameraStatus(),
        model: camera.getCameraModel(),
        model_id: camera.getCameraModelId(),
        address: camera.getCameraIp(),
        mac: camera.getCameraMac(),
        url: camera.getCameraUrl(),
        company: camera.getCameraCompanyName(),
        company_id: camera.getCameraCompanyId(),
        service: camera.getCameraServiceName(),
        service_id: camera.getCameraServiceId(),
        created: camera.getCameraCreated(),
        login: camera.getCameraLogin(),
        password: camera.getCameraPassword(),
        survey_period: camera.getCameraSurveyPeriod(),
      })
    }
  }
  return rows
})

const getModelListItems = computed(() => {
  const items = store.getters.getModelListItems

  let rows: any = []
  if (items !== undefined) {
    for (let model of items) {
      rows.push({
        value: model.getModelId(),
        label: model.getModelName()
      })
    }
  }
  return rows
})

const getServiceListItems = computed(() => {
  const items = store.getters.getServiceListItems
  let rows: any = []
  if (items !== undefined) {
    for (let item of items) {
      rows.push({
        value: item.getServiceId(),
        label: item.getServiceName()
      })
    }
  }
  return rows
})

const getCompanyListItems = computed(() => {
  const items = store.getters.getCompanyListItems

  let rows: any = []
  if (items !== undefined) {
    for (let model of items) {
      rows.push({
        value: model.getCompanyId(),
        label: model.getCompanyName()
      })
    }
  }

  return rows
})

const watchCompany = (id: any) => {
  router.push(`/companies/watch/${id}`)
}

//Переход на страницу список сервисов
const watchService = (id: any) => {
  router.push(`/services?service_id=${id}`)
}

//Переход на страницу список моделей
const watchModel = (id: any) => {
  router.push(`/models?model_id=${id}`)
}
//Просмотр камеры
const watchCamera = () => {
  router.push(`/cameras/watch/${selectedCameraId.value}`)
}

//Закрыть модальные окна
const hideModal = () => {
  isActiveModal.value = false
  isActiveModalAdd.value = false
  isActiveModalEdit.value = false
  isActiveModalDelete.value = false
}

//Изменить камеру
const editCamera = () => {
  isActiveModalEdit.value = true
  isActiveModal.value = true
}

//Добавить камеру
const addCamera = () => {
  isActiveModalAdd.value = true
  isActiveModal.value = true
}



//Функционал кнопок в таблице
const actionRow = (method: any, id: any) => {
  selectedCameraId.value = id
  if (method === 'edit') editCamera()
  if (method === 'watch') watchCamera()
  else console.log('Неизвестный метод')
}

//Прогрузка после взаимодействий с камерами
const afterAction = () => {
  loadCamerasListItems()
  hideModal()
}

//Запрос к таблице с камерами
const onRequestCamera = (props: any) => {
  cameraPagination.value = props.pagination
  loadCamerasListItems()
}

//Фильтр сервисов
const getServiceFilters = () => {
  return [
    {
      field_name: 'service_name',
      operand: '>',
      value: 0
    }
  ]
}

//Фильтр компаний
const getCompanyFilters = () => {
  return [
    {
      field_name: 'company_id',
      operand: '>',
      value: 0
    }
  ]
}

//Фильтр камер
const getCameraFilters = () => {
  let filter = []
  if (window.location.href.includes('?company_id')) {
    const link = new URL(window.location.href).searchParams.get('company_id')
    filter.push({
      field_name: 'camera_company_id',
      operand: '=',
      value: link
    })
  }
  if (window.location.href.includes('?service_id')) {
    const link = new URL(window.location.href).searchParams.get('service_id')
    filter.push({
      field_name: 'camera_service_id',
      operand: '=',
      value: link
    })
  }
  if (window.location.href.includes('?model_id')) {
    const link = new URL(window.location.href).searchParams.get('model_id')
    filter.push({
      field_name: 'camera_model_id',
      operand: '=',
      value: link
    })
  }
  if (selectedModel.value > 0) {
    filter.push({
      field_name: 'camera_model_id',
      operand: '=',
      value: selectedModel.value
    })
  }
  if (selectedCompany.value > 0) {
    filter.push({
      field_name: 'camera_company_id',
      operand: '=',
      value: selectedCompany.value
    })
  }
  if (selectedService.value > 0) {
    filter.push({
      field_name: 'camera_service_id',
      operand: '=',
      value: selectedService.value
    })
  }
  if (selectedName.value.length > 0) {
    filter.push({
      field_name: 'camera_name',
      operand: 'ilike',
      value: selectedName.value
    })
  }
  if (selectedId.value > 0) {
    filter.push({
      field_name: 'camera_id',
      operand: '=',
      value: selectedId.value
    })
  }
  return filter
}

//Фильтр моделей
const getModelFilters = () => {
  return [
    {
      field_name: 'model_id',
      operand: '>',
      value: 0
    }
  ]
}

//Загрузка списка камер
const loadCamerasListItems = () => {
  store
    .dispatch('getCamerasListItems', {

      filter: getCameraFilters(),
      sort: {
        name: cameraPagination.value.sortBy,
        exp: cameraPagination.value.descending ? 'DESC' : 'ASC',
      }
    })
    .then(
      (resolve) => {
        console.log('getCamerasListItems resolve', resolve)
        cameraPagination.value.rowsNumber = resolve.pagination.getCnt()
      },
      (reject) => {
        console.log('getCamerasListItems reject', reject)
      }
    )
}

//Загрузка списка сервисов
const loadServiceListItems = () => {
  store
    .dispatch('getServiceListItems', {
      filter: getServiceFilters(),
      sort: {
        name: servicePagination.value.sortBy,
        exp: servicePagination.value.descending ? 'DESC' : 'ASC',
      }
    })
    .then(
      (resolve) => {
        console.log('getServiceListItems resolve', resolve)
      },
      (reject) => {
        console.log('getServiceListItems reject', reject)
      }
    )
}

//Загрузка списка моделей
const loadModelListItems = () => {
  store
    .dispatch('getModelListItems', {
      filter: getModelFilters(),
      sort: {
        name: modelPagination.value.sortBy,
        exp: modelPagination.value.descending ? 'DESC' : 'ASC',
      }
    })
    .then(
      (resolve) => {
        console.log('getModelListItems resolve', resolve)
      },
      (reject) => {
        console.log('getModelListItems reject', reject)
      }
    )
}

//Загрузка списка компаний
const loadCompanyListItems = () => {
  store
    .dispatch('getCompanyListItems', {
      filter: getCompanyFilters(),
      sort: {
        name: companyPagination.value.sortBy,
        exp: companyPagination.value.descending ? 'DESC' : 'ASC',
      }
    })
    .then(
      (resolve) => {
        console.log('getCompanyListItems resolve', resolve)
      },
      (reject) => {
        console.log('getCompanyListItems reject', reject)
      }
    )
}

//Поиск
const search = () => {
  let newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);
  loadCamerasListItems()
}

//Очистка полей фильтров и поиска
const clearSelect = () => {
  let newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);
  selectedId.value = ''
  selectedName.value = ''
  selectedModel.value = ''
  selectedCompany.value = ''
  selectedService.value = ''
  loadCamerasListItems()
}

//Вызов функций загрузки данных
hideModal()
loadCamerasListItems()
loadServiceListItems()
loadModelListItems()
loadCompanyListItems()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, "Камеры: " + _toDisplayString(getCamerasListItems.value.length), 1)
              ]),
              _createVNode(_component_q_btn, {
                color: "primary",
                disable: isCamerasListBusy.value,
                label: "Добавить",
                icon: "mdi-plus",
                onClick: addCamera
              }, null, 8, ["disable"])
            ]),
            _createVNode(_component_q_table, {
              flat: "",
              style: {"height":"100%"},
              rows: getCamerasListItems.value,
              columns: columns.value,
              "row-key": "id",
              loading: isCamerasListBusy.value,
              onRequest: onRequestCamera
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  class: "id",
                  dense: "",
                  modelValue: selectedId.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedId).value = $event)),
                  filled: "",
                  label: "ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: selectedName.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedName).value = $event)),
                  filled: "",
                  label: "Название"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_select, {
                  modelValue: selectedModel.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedModel).value = $event)),
                  options: getModelListItems.value,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Модели"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_select, {
                  modelValue: selectedCompany.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedCompany).value = $event)),
                  options: getCompanyListItems.value,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Компании"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_select, {
                  modelValue: selectedService.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedService).value = $event)),
                  options: getServiceListItems.value,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Сервисы"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_btn, { onClick: clearSelect }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_btn, { onClick: search }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                })
              ]),
              "body-cell-status": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", null, _toDisplayString(props.row.status), 1),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-name_model": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (props.row.name)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("p", null, _toDisplayString(props.row.name), 1),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(props.row.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.model)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          (props.row.model_id !== 1)
                            ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: "button",
                                onClick: ($event: any) => (watchModel(props.row.model_id))
                              }, _toDisplayString(props.row.model), 9, _hoisted_9))
                            : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(props.row.model), 1))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-address_mac_url": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (props.row.address)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("p", null, _toDisplayString(props.row.address), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.mac)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("p", null, _toDisplayString(props.row.mac), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.url)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("p", null, _toDisplayString(props.row.url), 1),
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(props.row.url), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-company_service": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (props.row.company)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          (props.row.company_id !== 1)
                            ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: "button",
                                onClick: ($event: any) => (watchCompany(props.row.company_id))
                              }, _toDisplayString(props.row.company), 9, _hoisted_15))
                            : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(props.row.company), 1))
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.service)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          (props.row.service_id !== 1)
                            ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: "button",
                                onClick: ($event: any) => (watchService(props.row.service_id))
                              }, _toDisplayString(props.row.service), 9, _hoisted_18))
                            : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(props.row.service), 1))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-login_password": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  class: "login",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.login)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("p", null, _toDisplayString(props.row.login), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-survey_period": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-created": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('edit', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 192 192",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xml:space": "preserve",
                                  fill: "none"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67",
                                      class: "cls-1",
                                      style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-dasharray":"none","stroke-opacity":"1"},
                                      transform: "translate(-77.923 40.646)"
                                    }),
                                    _createElementVNode("path", {
                                      d: "m195.656 33.271-52.882 52.882",
                                      style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"5","stroke-dasharray":"none","stroke-opacity":"1"},
                                      transform: "translate(-77.923 40.646)"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('watch', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                      stroke: "#000000",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "loading"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isActiveModal).value = $event))
    }, {
      default: _withCtx(() => [
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(CameraAdd, {
              key: 0,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(CameraEdit, {
              key: 1,
              cameraId: selectedCameraId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["cameraId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})