import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "change-list" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "pre-header" }
const _hoisted_5 = { style: {"margin":"0"} }
const _hoisted_6 = {
  key: 0,
  class: "options-block"
}
const _hoisted_7 = {
  key: 0,
  class: "options-block"
}
const _hoisted_8 = {
  key: 1,
  class: "options-block"
}
const _hoisted_9 = {
  key: 0,
  class: "key"
}
const _hoisted_10 = {
  key: 1,
  class: "old"
}
const _hoisted_11 = {
  key: 2,
  class: "text"
}
const _hoisted_12 = {
  key: 3,
  class: "new"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, "Изменения: " + _toDisplayString(_ctx.getChangeListItems.length), 1)
              ])
            ]),
            _createVNode(_component_q_table, {
              flat: "",
              columns: _ctx.columns,
              rows: _ctx.getChangeListItems,
              "row-key": "id",
              loading: _ctx.isChangeListBusy,
              onRequest: _ctx.onRequestChange
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event)),
                  filled: "",
                  label: "ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_select, {
                  modelValue: _ctx.selectedType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedType) = $event)),
                  options: _ctx.eventList,
                  filled: "",
                  label: "Item Type"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: _ctx.selectedItemId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItemId) = $event)),
                  filled: "",
                  label: "Item ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_select, {
                  modelValue: _ctx.selectedEvent,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEvent) = $event)),
                  options: _ctx.typeList,
                  filled: "",
                  "emit-value": "",
                  label: "Event"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_select, {
                  modelValue: _ctx.selectedAuthor,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedAuthor) = $event)),
                  options: _ctx.getUsersNameListItems,
                  filled: "",
                  "emit-value": "",
                  "map-options": "",
                  label: "Пользователь"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.clearSelect
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  class: "button",
                  onClick: _ctx.search
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              "body-cell-id": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-type": _withCtx((props) => [
                _createVNode(_component_q_td, { class: "item" }, {
                  default: _withCtx(() => [
                    (props.row.item_type || props.row.item_id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("p", null, _toDisplayString(props.row.item_type), 1),
                          _cache[7] || (_cache[7] = _createTextVNode("/")),
                          _createElementVNode("p", null, _toDisplayString(props.row.item_id), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              "body-cell-events": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-authorDate": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (props.row.author)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("p", null, _toDisplayString(props.row.author), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.date)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("p", null, _toDisplayString(props.row.date), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-change": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.changes, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item,
                        class: "options-block changes"
                      }, [
                        (item.key)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.key) + " :", 1))
                          : _createCommentVNode("", true),
                        (item.old)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.old), 1))
                          : _createCommentVNode("", true),
                        (item.old)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "update to"))
                          : _createCommentVNode("", true),
                        (item.new)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.new), 1))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["columns", "rows", "loading", "onRequest"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}