import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"640px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode("Редактирование системного пользователя: " + _toDisplayString(_ctx.item.username), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isUsersListBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.saveUser,
              greedy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.last_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.last_name) = $event)),
                      label: "Фамилия",
                      filled: "",
                      "lazy-rules": "",
                      rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.first_name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.first_name) = $event)),
                      label: "Имя",
                      filled: "",
                      "lazy-rules": "",
                      rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.middle_name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.middle_name) = $event)),
                      label: "Отчество",
                      filled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.email,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.email) = $event)),
                      label: "E-mail",
                      filled: "",
                      "lazy-rules": "",
                      rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_select, {
                      ref: "model",
                      modelValue: _ctx.item.user_role,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.user_role) = $event)),
                      options: _ctx.getUserRolesListItems,
                      "option-label": "label",
                      "option-value": "value",
                      filled: "",
                      "map-options": "",
                      "emit-value": "",
                      label: "Роли",
                      rules: [
              (val) => (val && val.length > 0 ) || 'Поле должно быть заполнено',
            ]
                    }, null, 8, ["modelValue", "options", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.username,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.username) = $event)),
                      label: "Логин",
                      filled: "",
                      "lazy-rules": "",
                      rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: _ctx.item.password,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.password) = $event)),
                      label: "Пароль",
                      filled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_actions, { align: "right" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      color: "positive",
                      type: "submit"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Сохранить ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      onClick: _ctx.closeUserAdd
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Отмена ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit"]),
            _createVNode(_component_q_separator)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}