import { MessageServicePromiseClient } from "../../proto/pb/message_grpc_web_pb";
import {
  ListMessageRequest,
  Message,
  MessageFilter,
  MessageFilterItem,
  MessagePagination,
  MessageSort,
} from "../../proto/pb/message_pb";
import { RefreshTokenInterceptor } from "../../../users/services/refreshTokenInterceptor";
import { getAuthToken } from "../../../users/services/authMetadata";

// Количество на странице по-умолчанию
const defaultLimit: number = 100;

export class MessageApi {
  items: Array<Message> = [];
  client: MessageServicePromiseClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new MessageServicePromiseClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
  }

  // Установка и получение фильтров
  createFilter(data: any) {
    const filter = new MessageFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new MessageFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }
    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {
    const sort = new MessageSort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "message_text";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);
    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {
    const pagination = new MessagePagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getMessageListItems(filter: any, sort: any, pagination: any) {
    const request = new ListMessageRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listMessage(request, getAuthToken());
  }
}
