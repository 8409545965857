import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { CompanyApi } from '../services/api/CompanyApi';
import Loading from 'quasar/src/plugins/loading/Loading.js';

// Создаем объект
const companyApi = new CompanyApi();

// Создаем состояние
class State {
    isCompanyListBusy: boolean = false;
    companyListItems: unknown [];
    companyListItem: unknown;
    companyUsersListItems: unknown [];
    isCompanyUsersListBusy: boolean = false;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isCompanyListBusy(state): boolean {
        return state.isCompanyListBusy;
    },

    getCompanyListItems(state): unknown[] {
        return state.companyListItems;
    },

    getCompanyListItem(state): unknown {
        return state.companyListItem;
    },

    getCompanyUsersListItems(state): unknown[] {
        return state.companyUsersListItems;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsCompanyListBusy(state, payload: boolean) {
        state.isCompanyListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setCompanyListItems(state, payload: unknown[]) {
        state.companyListItems = payload
    },

    setCompanyListItem(state, payload: unknown) {
        state.companyListItem = payload
    },

    setIsCompanyUsersListBusy(state, payload: boolean) {
        state.isCompanyUsersListBusy = payload
        if (payload) {
            Loading.show()
        } else {
            Loading.hide()
        }
    },

    setCompanyUsersListItem(state, payload: unknown[]) {
        state.companyUsersListItems = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setCompanyListItem({ commit }, data: any) {
        commit('setCompanyListItem', data);
    },

    // Список элементов
    getCompanyListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsCompanyListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                companyApi.getCompanyListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setCompanyListItems', items);
                            commit('setIsCompanyListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setCompanyListItems', []);
                            commit('setIsCompanyListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Создать элемент
    createCompanyItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCompanyListBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            companyApi.createCompanyItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCompanyListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCompanyListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Редактировать элемент
    updateCompanyItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCompanyListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            companyApi.updateCompanyItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCompanyListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCompanyListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteCompanyListItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsCompanyListBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            companyApi.deleteCompanyItem(data)
                .then(
                    (response:any) => {
                        commit('setIsCompanyListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsCompanyListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Получить элемент
    getCompanyListItem({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsCompanyListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            companyApi.getCompanyListItem(filter)
                .then(
                    (response:any) => {
                        commit('setCompanyListItem', response.getItemsList()[0]);
                        commit('setIsCompanyListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsCompanyListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Связь компании и пользователя
    linkCompanyUser({ commit }, data){

        return new Promise((resolve, reject) => {

            // Создаем элемент
            companyApi.linkCompanyUserItem(data)
                .then(
                    (response:any) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    },
    // Список пользователей компании
    getCompanyUsersListItems({commit}, data: any) {

        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                companyApi.getCompanyUsersListItems(data, sort, pagination)
                    .then(
                        (message: any) => {
                            const items = message.getItemsList();
                            /*const sort = '';
                            const pagination = '';*/
                            commit('setCompanyUsersListItem', items)
                            commit('setIsCompanyUsersListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setCompanyUsersListItem', [])
                            commit('setIsCompanyUsersListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

};

const CompanyListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default CompanyListModule;