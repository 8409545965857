import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/img/header-logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh lpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        elevated: "",
        class: "text-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: "/",
                    style: {"text-decoration":"none","color":"white"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_avatar, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("img", {
                            src: _imports_0,
                            alt: "Логотип"
                          }, null, -1)
                        ])),
                        _: 1
                      }),
                      _cache[2] || (_cache[2] = _createTextVNode(" EasyPark "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.$route.path !== '/')
                ? (_openBlock(), _createBlock(_component_q_list, { key: 0 }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("a", { href: "/cameras" }, "Камеры", -1),
                      _createElementVNode("a", { href: "/companies" }, "Компании", -1),
                      _createElementVNode("a", { href: "/services" }, "Сервисы", -1),
                      _createElementVNode("a", { href: "/models" }, "Модели", -1),
                      _createElementVNode("a", { href: "/users/manage" }, "Пользователи", -1),
                      _createElementVNode("a", { href: "/message" }, "Сообщения", -1),
                      _createElementVNode("a", { href: "/statistic" }, "Статистика", -1),
                      _createElementVNode("a", { href: "/changes" }, "Изменения", -1),
                      _createElementVNode("a", { href: "/call-logs" }, "Запросы", -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickLogout())),
                color: "white",
                "text-color": "black",
                label: "Выход",
                icon: "logout",
                "no-caps": "",
                unelevated: "",
                class: "exit-button"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_Breadcrumbs),
          _createVNode(_component_router_view, { class: "router-view" }, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "page-opacity",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}