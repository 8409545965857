import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "main-page__list" }

import { onMounted } from 'vue';
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const router = useRouter()
const userRole = localStorage.getItem('userRole')

onMounted(() => {
  window.stop()
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Камеры",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/cameras')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Компании",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/companies')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Сервисы",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/services')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Модели",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/models')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Пользователи",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/users/manage')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Сообщения",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/message')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Статистика",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(router).push('/statistic')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Изменения",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(router).push('/changes')))
      }),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Запросы",
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(router).push('/call-logs')))
      }),
      (_unref(userRole) === 'super')
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            color: "primary",
            label: "Ручная детекция",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(router).push('/manual-detection')))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})