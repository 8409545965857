<template>

  <div class="service-add">
    <q-card
        class="q-pa-md"
    >
      <q-item>
        <q-card-section>
          <q-item-label class="text-h5"
          >Добавить сервисы
          </q-item-label
          >
        </q-card-section>
      </q-item>
      <div v-if="!isServiceListBusy">
        <q-form
          @submit="saveService"
          greedy
        >
          <q-card-section>
            <q-input
                v-model="item.name"
                label="Название"
                filled
                lazy-rules
                :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
            />
          </q-card-section>
          <q-card-section>
            <q-input
                v-model="item.comment"
                label="Примечание"
                filled
                lazy-rules
                :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
            />
          </q-card-section>
          <q-card-actions style="display: flex;justify-content: space-between;padding: 16px">
            <q-btn color="positive" type="submit"> Сохранить</q-btn>
            <q-btn color="negative" @click="closeServiceAdd"> Отмена</q-btn>
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </div>

</template>

<script lang="ts">

/*import store from "@/store";*/
import {defineComponent} from 'vue'
import store from "@/store";
import { useRouter } from 'vue-router'

export default defineComponent({
  name: "ServiceAdd",

  data() {
    return {
      router: useRouter(),
      item: {
        name: '',
        comment: ''
      },
    }
  },

  computed: {
    isServiceListBusy() {
      return store.getters.getIsServiceListBusy
    },
  },

  methods: {

    //Закрыть модалку
    closeServiceAdd(){
      this.router.push('/services')
    },

    //Сохранить сервис
    saveService() {

      let data = {
        name: this.item.name,
        comment: this.item.comment
      }

      store.dispatch('createServiceItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.router.push('/services')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )

      return true
    },

  }

})
</script>

<style lang="scss">

.service-add{
  .q-pa-md{
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
  }
  .tittle{
    padding: 0 16px;
    margin: 0;
    h3{
      font-size: 22px;
      line-height: 100%;
      margin: 0 0 16px 0;
    }
  }
}

</style>