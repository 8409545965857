<template>

  <div class="model-list">
    <div class="model-list__pre-header">
      <div>
        <h3 style="margin: 0;">Модели: {{ getModelListItems.length }}</h3>
      </div>
      <q-btn color="primary" icon="mdi-plus" label="Добавить" @click="addModel" style="width:fit-content;margin: 0 0 16px auto"/>
    </div>

    <div class="model-list__table">
      <q-table style="box-shadow: none"
          :columns="modelColumn"
          :rows="getModelListItems"
          :loading="isModelListBusy"
          row-key="id"
          @request="onRequestModel"
      >
        <template v-slot:body-cell-address="props">
          <q-td :props="props">
            <div class="options-block" v-if="props.row.grabber_address">
              <p>{{ props.row.grabber_address }}</p>
            </div>
            <div class="options-block" v-if="props.row.snapshot_address">
              <p>{{ props.row.snapshot_address }}</p>
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-options="props">
          <q-td :props="props">
            <div class="options-block" v-if="props.row.vendor">
              <h3>Vendor</h3>
              <p>{{ props.row.vendor }}</p>
            </div>
            <div class="options-block"  v-if="props.row.low_res_source">
              <h3>Video source low</h3>
              <p>{{ props.row.low_res_source }}</p>
            </div>
            <div class="options-block" v-if="props.row.mid_res_source">
              <h3>Video source middle</h3>
              <p>{{ props.row.mid_res_source }}</p>
            </div>
            <div class="options-block" v-if="props.row.hi_res_source">
              <h3>Video source high</h3>
              <p>{{ props.row.hi_res_source }}</p>
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-cameras_count="props">
          <q-td :props="props">
            <span class="button" @click="watchCamera(props.row.id)">{{ props.value }}</span>
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-list style="display: flex">
              <q-item clickable @click="actionRow('edit', props.value)">
                <q-item-section>
                  <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg"
                       xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path>
                      <path d="m195.656 33.271-52.882 52.882"
                            style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1"
                            transform="translate(-77.923 40.646)"></path></g></svg>
                </q-item-section>
              </q-item>
              <q-item clickable @click="actionRow('delete', props.value)">
                <q-item-section>
                  <svg fill="#ff0000" version="1.1" id="Capa_1" width="18px" height="18px" viewBox="0 0 482.428 482.429" xml:space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path> <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path> <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path> </g> </g> </g></svg>
                </q-item-section>
              </q-item>
            </q-list>
          </q-td>
        </template>

      </q-table>
    </div>
    <q-dialog v-model="isActiveModal">
      <modelDelete
          v-if="isActiveModalDelete"
          :modelId="selectedModelId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>


</template>

<script lang="ts">


import store from '@/store'
import {defineComponent} from 'vue'
import modelDelete from './ModelDelete.vue'
import { useRouter } from 'vue-router'

export default defineComponent({

  components: {
    modelDelete
  },

  name: "ModelList",


  data(){
    return{
      router: useRouter(),
      item: {
        id: 0,
        name: '',
        grabber_address: '',
        snapshot_address: '',
        vendor: '',
        low_res_source: '',
        mid_res_source: '',
        hi_res_source: '',
        camera_count: 0
      },
      modelColumn: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'address',
          label: 'Адрес grabber \nПолучение snapshot',
          field: 'address',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'options',
          label: 'Параметры',
          field: 'options',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'cameras_count',
          label: 'Камеры',
          field: 'cameras_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: '',
          field: 'id',
          align: 'left',
          style: 'width: 18px; padding: 0 15px 15px 15px !important;'
        }
      ] as any,
      modelPagination: {
        sortBy: 'model_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      searchResult: [] as any,
      isActiveModalDelete: false,
      isActiveModal: false,
      selectedModelId: null as any
    }
  },

  computed: {
    isModelListBusy() {
      return store.getters.getIsModelListBusy
    },

    //Формирование списка моделей
    getModelListItems() {
      const items = store.getters.getModelListItems

      let rows: any = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            id: model.getModelId(),
            name: model.getModelName(),
            grabber_address: model.getModelGrabberAddress(),
            snapshot_address: model.getModelSnapshotAddress(),
            vendor: model.getModelVendor(),
            low_res_source: model.getModelLowResSource(),
            mid_res_source: model.getModelMidResSource(),
            hi_res_source: model.getModelHiResSource(),
            cameras_count: model.getModelCameraCount()
          })
        }
      }
      return rows
    },
  },

  methods: {

    //Просмотр камеры
    watchCamera(id: any){
      this.router.push('/cameras?model_id=' + id)
    },

    //Закрыть модалки
    hideModal(){
      this.isActiveModal = false
      this.isActiveModalDelete = false
      this.selectedModelId = null
    },

    //Изменить модель
    editModel(){
      this.router.push('/models/edit/' + this.selectedModelId)
    },

    //Добавить модель
    addModel(){
      this.router.push('/models/add')
    },

    //Удалить модель
    deleteModel(){
      this.isActiveModalDelete = true
      console.log('deleteModel')
      this.isActiveModal = true
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedModelId = id
      method === 'delete' ? this.deleteModel() :
          method === 'edit' ? this.editModel() : console.log('Неизвестный метод')
    },

    //Подгрузка после взаимодействия
    afterAction() {
      console.log('afterAction')
      this.loadModelListItems()
      this.hideModal()
    },

    //Запрос к таблице
    onRequestModel(props: any) {
      console.log('onRequestModel props', props)
      this.modelPagination = props.pagination
      this.loadModelListItems()
    },

    //Фильтр моделей
    getFilters(){
      const link = new URL(window.location.href).searchParams.get('model_id')
      if(link){
        return [
          {
            field_name: 'model_id',
            operand: '=',
            value: link,
          }
        ]
      } else{
        return [
          {
            field_name: 'model_name',
            operand: '>',
            value: 0,
          }
        ]
      }
    },

    //Загрузка списка моделей
    loadModelListItems() {
      store
          .dispatch('getModelListItems', {
            filter: this.getFilters(),
            sort: {
              name: this.modelPagination.sortBy,
              exp: this.modelPagination.descending ? 'DESC' : 'ASC',
            },
          })
          .then(
              (resolve) => {
                console.log('getModelListItems resolve', resolve)
                this.modelPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getModelListItems reject', reject)
              }
          )
    },

  },

  created(){
    this.loadModelListItems()
  }

})
</script>

<style lang="scss">

.model-list{
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__pre-header{
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
      margin: 0;
    }
    .q-btn{
      padding: 12px 24px 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }

  .q-table__middle{
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .button{
      color: #027BE3;
      cursor: pointer;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  &__table{
    td{
      padding: 15px !important;
      vertical-align: top;
    }
    .options-block{
      margin: 0 0 15px 0;
      h3{
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 100%;
      }
      p{
        padding: 0;
        margin: 0;
        font-size: 13px;
        line-height: 100%;
      }
    }
  }
}

</style>