import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "users",
  style: {"height":"calc(100% - 50px)"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "pre-header" }
const _hoisted_4 = { style: {"margin":"0"} }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = ["onClick"]

import store from '@/store'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import UserAdd from './UserAdd.vue'
import UserDelete from './UserDelete.vue'
import UserEdit from './UserEdit.vue'
import type { QTableColumn } from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersList',
  setup(__props) {

const router = useRouter()

const columns = ref<QTableColumn[]>([
  {
    name: 'name',
    label: 'Ф.И.О',
    field: 'fio',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'email',
    label: 'E-mail',
    field: 'email',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'role',
    label: 'Роль',
    field: 'role',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'company_count',
    label: 'Количество компаний',
    field: 'company_count',
    align: 'left',
    headerStyle: 'font-weight: 600;font-size: 14px;'
  },
  {
    name: 'action',
    label: 'Действие',
    field: 'id',
    align: 'center',
    headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
    style: 'width: 40px',
  }
]);
const userPagination = ref({
  sortBy: 'username',
  descending: false,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
})
const rowsPerPageUserOptions = ref([5, 10, 25, 50, 100])
const selectedId = ref()
const selectedName = ref()
const isActiveModal = ref(false)
const isActiveModalAdd = ref(false)
const isActiveModalEdit = ref(false)
const isActiveModalDelete = ref(false)
const selectedUserId = ref()
const tableKey = ref(0)
const userRole = ref(localStorage.getItem('userRole'))

const isUsersListBusy = computed(() => {
  return store.getters.getIsUsersListBusy
})

const getUsersListItems = computed(() => {
  const users = store.getters.getUsersListItems

  let rows: any = []
  if (users !== undefined) {
    for (let user of users) {
      rows.push({
        id: user.getUserId(),
        name: user.getFirstName(),
        email: user.getEmail(),
        fio: user.getLastName() + ' ' + user.getFirstName() + ' ' + user.getMiddleName(),
        role: user.getUserRole(),
        company_count: user.getCompanyCount(),
        companies: []
      })
    }
  }
  return rows
})

const hideModal = () => {
  isActiveModal.value = false
  isActiveModalAdd.value = false
  isActiveModalEdit.value = false
  isActiveModalDelete.value = false
  selectedUserId.value = null
}

const addUser = () => {
  isActiveModalAdd.value = true
  isActiveModal.value = true
}

const editUser = () => {
  isActiveModalEdit.value = true
  isActiveModal.value = true
}


const deleteUser = () => {
  isActiveModalDelete.value = true
  isActiveModal.value = true
}

const actionRow = (method: string, id: number) => {
  selectedUserId.value = id
  method === 'watch' ? router.push(`'/users/watch/${id}`) :
    method === 'edit' ? editUser() :
      method === 'delete' ? deleteUser() : console.log('Неизвестный метод')
}

const afterAction = () => {
  loadUsersListItems(true)
  hideModal()
}

const onRequestUser = (props: any) => {
  userPagination.value = props.pagination
  loadUsersListItems(false)
}

const clearSelect = () => {
  const newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);
  selectedId.value = ''
  selectedName.value = ''
  loadUsersListItems(true)
}

const search = () => {
  const newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);
  loadUsersListItems(true)
}


const getFilters = () => {
  let filter = []
  if (selectedName.value && selectedName.value.length > 0) {
    filter.push({
      field_name: 'first_name',
      operand: 'ilike',
      value: selectedName.value,
      or: [
        {
          field_name: 'last_name',
          operand: 'ilike',
          value: selectedName.value,
        },
        {
          field_name: 'middle_name',
          operand: 'ilike',
          value: selectedName.value,
        }
      ]
    })
  }
  if (selectedId.value > 0) {
    filter.push({
      field_name: 'user_id',
      operand: '=',
      value: selectedId.value
    })
  }
  return filter
}

const loadUsersListItems = (isResetPagination: boolean) => {
  if (isResetPagination) {
    userPagination.value.page = 1
    userPagination.value.rowsNumber = 0
  }
  store
    .dispatch('getUsersListItems', {
      filter: getFilters(),
      sort: {
        name: userPagination.value.sortBy,
        exp: userPagination.value.descending ? 'DESC' : 'ASC',
      },
      pagination: {
        page: userPagination.value.page,
        limit: userPagination.value.rowsPerPage,
        pages: 0,
        cnt: userPagination.value.rowsNumber,
      },
    })
    .then(
      (resolve) => {
        console.log('getUsersListItems resolve', resolve)
        userPagination.value.rowsNumber = resolve.pagination.getCnt()
        getUsersListItems.value.forEach((user: any) => {
          getUserCompaniesList(user)
        })
      },
      (reject) => {
        console.log('getUsersListItems reject', reject)
      }
    )
}

const getUserCompaniesList = (user: any) => {
  store
    .dispatch('getUserCompaniesListItems', {
      id: user.id
    })
    .then(
      (resolve) => {
        console.log('getUserCompaniesListItems resolve', resolve)
        resolve.items.forEach((company: any) => {
          user.companies.push(company.array[1])
        })
        tableKey.value++
        setTimeout(() => {
          if (window.location.href.includes('?company_name')) {
            const link = new URL(window.location.href).searchParams.get('company_name')
            getUsersListItems.value.forEach((userUnit: any, idx: any) => {
              !userUnit.companies.includes(link) ? getUsersListItems.value.splice(idx, 1) : ''
              tableKey.value++
            })
          }
        }, 50)
      },
      (reject) => {
        console.log('getUserCompaniesListItems reject', reject)
      }
    )
}

hideModal()
loadUsersListItems(true)

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, "Пользователи: " + _toDisplayString(getUsersListItems.value.length), 1),
            (userRole.value === 'super' || userRole.value === 'admin')
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  color: "primary",
                  disable: isUsersListBusy.value,
                  label: "Добавить",
                  icon: "mdi-plus",
                  onClick: addUser
                }, null, 8, ["disable"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_q_table, {
              flat: "",
              rows: getUsersListItems.value,
              columns: columns.value,
              "row-key": "id",
              loading: isUsersListBusy.value,
              pagination: userPagination.value,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((userPagination).value = $event)),
              "rows-per-page-options": rowsPerPageUserOptions.value,
              onRequest: onRequestUser,
              key: tableKey.value
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: selectedId.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedId).value = $event)),
                  filled: "",
                  label: "ID"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_input, {
                  dense: "",
                  modelValue: selectedName.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedName).value = $event)),
                  filled: "",
                  label: "Название"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_q_btn, { onClick: clearSelect }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Очистить")
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_btn, { onClick: search }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Отфильтровать")
                  ])),
                  _: 1
                })
              ]),
              "body-cell-name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-email": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-role": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value), 1)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-company_count": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "button",
                      onClick: ($event: any) => (_unref(router).push(`/companies?username=${props.row.name}`))
                    }, _toDisplayString(props.value), 9, _hoisted_6)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('delete', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createElementVNode("svg", {
                                  fill: "#ff0000",
                                  version: "1.1",
                                  id: "Capa_1",
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 482.428 482.429",
                                  "xml:space": "preserve",
                                  stroke: "#ff0000"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("g", null, [
                                      _createElementVNode("g", null, [
                                        _createElementVNode("path", { d: "M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z" }),
                                        _createElementVNode("path", { d: "M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z" }),
                                        _createElementVNode("path", { d: "M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z" }),
                                        _createElementVNode("path", { d: "M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z" })
                                      ])
                                    ])
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('edit', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 192 192",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xml:space": "preserve",
                                  fill: "none"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67",
                                      class: "cls-1",
                                      style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-dasharray":"none","stroke-opacity":"1"},
                                      transform: "translate(-77.923 40.646)"
                                    }),
                                    _createElementVNode("path", {
                                      d: "m195.656 33.271-52.882 52.882",
                                      style: {"fill":"none","fill-opacity":"1","fill-rule":"nonzero","stroke":"#000000","stroke-width":"12","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"5","stroke-dasharray":"none","stroke-opacity":"1"},
                                      transform: "translate(-77.923 40.646)"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_q_item, {
                          clickable: "",
                          onClick: ($event: any) => (actionRow('watch', props.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createElementVNode("svg", {
                                  width: "18px",
                                  height: "18px",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("g", {
                                    id: "SVGRepo_bgCarrier",
                                    "stroke-width": "0"
                                  }),
                                  _createElementVNode("g", {
                                    id: "SVGRepo_tracerCarrier",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                    _createElementVNode("path", {
                                      d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                      stroke: "#000000",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ])
                                ], -1)
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "loading", "pagination", "rows-per-page-options"]))
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isActiveModal).value = $event))
    }, {
      default: _withCtx(() => [
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(UserAdd, {
              key: 0,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(UserEdit, {
              key: 1,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(UserDelete, {
              key: 2,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})