import { ServiceServicePromiseClient } from "../../proto/pb/service_grpc_web_pb";
import {
  CreateServiceRequest,
  DeleteServiceRequest,
  ListServiceRequest,
  UpdateServiceRequest,
  Service,
  ServiceFilter,
  ServiceFilterItem,
  ServicePagination,
  ServiceSort,
} from "../../proto/pb/service_pb";
import { RefreshTokenInterceptor } from "../../../users/services/refreshTokenInterceptor";
import { getAuthToken } from "../../../users/services/authMetadata";

// Количество на странице по-умолчанию
const defaultLimit: number = 100;

export class ServiceApi {
  items: Array<Service> = [];
  client: ServiceServicePromiseClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new ServiceServicePromiseClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
  }

  // Установка и получение фильтров
  createFilter(data: any) {
    const filter = new ServiceFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new ServiceFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }
    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {
    const sort = new ServiceSort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "service_name";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);
    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {
    const pagination = new ServicePagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getServiceListItems(filter: any, sort: any, pagination: any) {
    const request = new ListServiceRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listService(request, getAuthToken());
  }

  // Получение элемента
  getServiceListItem(filter: any) {
    const request = new ListServiceRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listService(request, getAuthToken());
  }

  // Создание элемента
  createServiceItem(data: any) {
    const request = new CreateServiceRequest();
    request.setServiceName(data.name);
    request.setServiceComment(data.routing_key);

    return this.client.createService(request, getAuthToken());
  }

  // Редактировать элемент
  updateServiceItem(data: any) {
    const request = new UpdateServiceRequest();
    request.setServiceId(data.id);
    request.setServiceName(data.name);
    request.setServiceComment(data.routing_key);

    return this.client.updateService(request, getAuthToken());
  }

  // Удаление элемента
  deleteServiceItem(data: any) {
    const request = new DeleteServiceRequest();
    request.setServiceId(data.id);

    return this.client.deleteService(request, getAuthToken());
  }
}
