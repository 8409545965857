import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-watch" }
const _hoisted_2 = { class: "user-watch__header" }
const _hoisted_3 = { class: "company-watch__header-navigation" }
const _hoisted_4 = { class: "user-watch__body" }
const _hoisted_5 = { class: "user-watch__profile" }
const _hoisted_6 = { class: "user-watch__profile-body" }
const _hoisted_7 = { class: "user-watch__companies" }
const _hoisted_8 = { class: "user-watch__companies-header" }
const _hoisted_9 = { class: "user-watch__companies-body" }

import { ref, computed } from 'vue'
/*import store from "@/store";*/
import { useRouter, useRoute } from 'vue-router'
import type { QTableColumn } from 'quasar';
import UserDelete from "@/components/modules/users/views/users/UserDelete.vue";
import UserEdit from "@/components/modules/users/views/users/UserEdit.vue";
import UserCompanyAdd from "@/components/modules/users/views/users/UserCompanyAdd.vue";
import UserCompanyUnlink from "@/components/modules/users/views/users/UserCompanyUnlink.vue";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserWatch',
  setup(__props) {


const router = useRouter();
const route = useRoute();

const item = ref({
  user_id: 0,
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  role: '',
  created: ''
})

const columns = ref<QTableColumn[]>([
  {
    name: 'name',
    label: '',
    field: 'name',
    align: 'left',
    style: 'width: 100%'
  },
  {
    name: 'action',
    label: '',
    field: 'id',
    align: 'center'
  }
])
const isActiveModal = ref(false)
const isActiveModalEdit = ref(false)
const isActiveModalDelete = ref(false)
const isActiveModalAddCompanyUser = ref(false)
const isActiveModalDeleteUserCompanyLink = ref(false)
const selectedUserId = ref<number>()
const selectedCompanyId = ref<number>()
// const selectedCompany = ref(null)


const isUserCompaniesListBusy = computed(() => {
  return store.getters.getIsUserCamerasListBusy
})

const getUserCompaniesListItems = computed(() => {
  const items = store.getters.getUserCompaniesListItems
  let rows: any = []
  if (items !== undefined) {
    for (let company of items) {
      rows.push({
        name: company.getCompanyName(),
        id: company.getCompanyId()
      })
    }
  }
  return rows
})

const hideModal = () => {
  isActiveModal.value = false
  isActiveModalEdit.value = false
  isActiveModalDelete.value = false
  isActiveModalAddCompanyUser.value = false
  isActiveModalDeleteUserCompanyLink.value = false
}

//Редактирование пользователя
const editUser = () => {
  selectedUserId.value = item.value.user_id
  isActiveModalEdit.value = true
  isActiveModal.value = true
}

//Удаление пользователя
const deleteUser = () => {
  selectedUserId.value = item.value.user_id
  isActiveModalDelete.value = true
  isActiveModal.value = true
}

//Добавление связи пользователь-компания
const addCompanyUser = () => {
  selectedUserId.value = item.value.user_id
  isActiveModalAddCompanyUser.value = true
  isActiveModal.value = true
}

//Удаление связи пользователь компания
const deleteLink = (id: number) => {
  selectedUserId.value = item.value.user_id
  selectedCompanyId.value = id
  isActiveModalDeleteUserCompanyLink.value = true
  isActiveModal.value = true
}

//Прогрузка после дейсвтия
const afterAction = () => {
  loadData()
  hideModal()
  getUserCompaniesList()
}

//Загрузка данных пользователя
const loadData = () => {
  store
    .dispatch('getUsersListItem', {
      filter: [
        {
          field_name: 'user_id',
          operand: '=',
          value: route.params.id,
        },
      ],
    })
    .then(
      (resolve) => {
        console.log('loadData resolve', resolve)
        item.value = {
          user_id: resolve.getUserId(),
          username: resolve.getUsername(),
          email: resolve.getEmail(),
          first_name: resolve.getFirstName(),
          last_name: resolve.getLastName(),
          role: resolve.getUserRole(),
          created: ''
        }
      },
      (reject) => {
        console.log('loadData reject', reject)
      }
    )
}

//Загрузка списка компаний пользователя
const getUserCompaniesList = () => {
  store
    .dispatch('getUserCompaniesListItems', {
      id: route.params.id
    })
    .then(
      (resolve) => {
        console.log('getUserCompaniesListItems resolve', resolve)
      },
      (reject) => {
        console.log('getUserCompaniesListItems reject', reject)
      }
    )
}

loadData()
getUserCompaniesList()


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(item.value.username), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn, {
          color: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (editUser()))
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Изменить ")
          ])),
          _: 1
        }),
        _createVNode(_component_q_btn, {
          color: "negative",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (deleteUser()))
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Удалить ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "user-watch__profile-header" }, [
          _createElementVNode("h3", null, "Профиль")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Email:", -1)),
              _createElementVNode("p", null, _toDisplayString(item.value.email), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Имя:", -1)),
              _createElementVNode("p", null, _toDisplayString(item.value.first_name), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Фамилия:", -1)),
              _createElementVNode("p", null, _toDisplayString(item.value.last_name), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Админ:", -1)),
              _createElementVNode("p", null, _toDisplayString(item.value.role), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Добавлен:", -1)),
              _createElementVNode("p", null, _toDisplayString(item.value.created), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Компании: ", -1)),
          _createVNode(_component_q_btn, {
            color: "white",
            "text-color": "black",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (addCompanyUser()))
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" Добавить ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_q_table, {
            flat: "",
            rows: getUserCompaniesListItems.value,
            columns: columns.value,
            "row-key": "id",
            loading: isUserCompaniesListBusy.value
          }, {
            "body-cell-action": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, { style: {"display":"flex"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item, {
                        clickable: "",
                        onClick: ($event: any) => (deleteLink(props.value))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createElementVNode("svg", {
                                fill: "#ff0000",
                                version: "1.1",
                                id: "Capa_1",
                                width: "18px",
                                height: "18px",
                                viewBox: "0 0 482.428 482.429",
                                "xml:space": "preserve",
                                stroke: "#ff0000"
                              }, [
                                _createElementVNode("g", {
                                  id: "SVGRepo_bgCarrier",
                                  "stroke-width": "0"
                                }),
                                _createElementVNode("g", {
                                  id: "SVGRepo_tracerCarrier",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }),
                                _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                  _createElementVNode("g", null, [
                                    _createElementVNode("g", null, [
                                      _createElementVNode("path", { d: "M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z" }),
                                      _createElementVNode("path", { d: "M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z" }),
                                      _createElementVNode("path", { d: "M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z" }),
                                      _createElementVNode("path", { d: "M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z" })
                                    ])
                                  ])
                                ])
                              ], -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_q_item, {
                        clickable: "",
                        onClick: ($event: any) => (_unref(router).push(`/companies/watch/${props.value}`))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createElementVNode("svg", {
                                width: "18px",
                                height: "18px",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("g", {
                                  id: "SVGRepo_bgCarrier",
                                  "stroke-width": "0"
                                }),
                                _createElementVNode("g", {
                                  id: "SVGRepo_tracerCarrier",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }),
                                _createElementVNode("g", { id: "SVGRepo_iconCarrier" }, [
                                  _createElementVNode("path", {
                                    d: "M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z",
                                    stroke: "#000000",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ])
                              ], -1)
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "no-data": _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
            ])),
            _: 1
          }, 8, ["rows", "columns", "loading"])
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isActiveModal).value = $event))
    }, {
      default: _withCtx(() => [
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(UserEdit, {
              key: 0,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(UserDelete, {
              key: 1,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        (isActiveModalAddCompanyUser.value)
          ? (_openBlock(), _createBlock(UserCompanyAdd, {
              key: 2,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        (isActiveModalDeleteUserCompanyLink.value)
          ? (_openBlock(), _createBlock(UserCompanyUnlink, {
              key: 3,
              userId: selectedUserId.value,
              companyId: selectedCompanyId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId", "companyId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})