<template>
  <div class="company-list">
    <q-card style="padding: 10px 10px 0 10px; height: 100%;; box-shadow: none">
      <div class="row" style="height: 100%">
        <div class="col-12" style="height: 100%;">
          <div class="pre-header">
            <div>
              <h3 style="margin: 0;">Компании: {{ getCompanyListItems.length }}</h3>
            </div>
            <q-btn
                color="primary"
                :disable="isCompanyListBusy"
                label="Добавить"
                icon="mdi-plus"
                @click="addCompany"
                v-if="userRole === 'super'"
            />
          </div>
          <q-table
              flat
              style="height: 100%;"
              :rows="getCompanyListItems"
              :columns="columns"
              row-key="id"
              :key="tableKey"
              :loading="isCompanyListBusy"
              @request="onRequestCompany"
          >
            <template v-slot:top>
              <q-input
                  dense
                  v-model="selectedId"
                  filled
                  label="ID"
              >
              </q-input>
              <q-input
                  dense
                  v-model="selectedName"
                  filled
                  label="Название"
              >
              </q-input>
              <q-btn class="button" @click="clearSelect">Очистить</q-btn>
              <q-btn class="button" @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-id="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-name="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-cameras-count="props">
              <q-td :props="props">
                <span class="button" @click="watchCamera(props.row.id)">{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-users-count="props">
              <q-td :props="props">
                <span class="button" @click="watchUsers(props.row.name)">{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-created="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item v-if="userRole === 'super' || userRole === 'admin'" clickable @click="actionRow('edit', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path><path d="m195.656 33.271-52.882 52.882" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script lang="ts">

import {defineComponent} from 'vue'
import store from "@/store";
import { useRouter } from 'vue-router'

export default defineComponent({
  name: "CompanyList",

  data(){
    return{
      router: useRouter(),
      columns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'cameras-count',
          label: 'Камеры',
          field: 'cameras_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'users-count',
          label: 'Пользователи',
          field: 'users_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'created',
          label: 'Дата добавления',
          field: 'created',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
          style: 'width: 40px'
        },
      ] as any,
      companyPagination: {
        sortBy: 'company_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0
      },
      selectedId: null as any,
      selectedName: '',
      searchResult: [] as any,
      selectedCompanyId: null as any,
      tableKey: 0,
      userRole: localStorage.getItem('userRole')
    }
  },

  computed: {

    isCompanyListBusy() {
      return store.getters.getIsCompanyListBusy
    },

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getCompanyId(),
            name: item.getCompanyName(),
            cameras_count: item.getCompanyCamerasCount(),
            users_count: item.getCompanyUsersCount(),
            created: item.getCompanyCreated(),
            users: []
          })
        }
      }
      return rows
    }

  },

  methods: {

    //Переход на просмотр списка пользователей
    watchUsers(name: any){
      this.router.push('/users/manage?company_name=' + name)
    },

    //Переход на просмотр списка компаний
    watchCamera(id: any){
      this.router.push('/cameras?company_id=' + id)
    },

    //Изменение компании
    editCompany() {
      this.router.push('/companies/edit/' + this.selectedCompanyId)
    },

    //Добавление компании
    addCompany() {
      this.router.push('/companies/add')
    },

    //Просмотр компании
    watchCompany(){
      this.router.push('/companies/watch/' + this.selectedCompanyId)
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedCompanyId = id
      method === 'edit' ? this.editCompany() :
          method === 'watch' ? this.watchCompany() : console.log('Неизвестный метод')
    },

    //Прогрузка после взаимодействий с компанией
    afterAction() {
      console.log('afterAction')
      this.loadCompanyListItems()
    },
    //Запрос к таблице с компаниями
    onRequestCompany(props: any) {
      console.log('onRequestCompany props', props)
      this.companyPagination = props.pagination
      this.loadCompanyListItems()
    },

    //Фильтры
    getFilters() {
      let filter = []
      if(this.selectedId > 0){
        filter.push({
          field_name: 'company_id',
          operand: '=',
          value: this.selectedId
        })
      }else{
        filter.push({
          field_name: 'company_id',
          operand: '>',
          value: 0
        })
      }
      if(this.selectedName.length > 0){
        filter.push({
          field_name: 'company_name',
          operand: 'ilike',
          value: this.selectedName
        })
      }
      return filter
    },

    //Загрузка списка компаний
    loadCompanyListItems() {
      store
          .dispatch('getCompanyListItems', {
            filter: this.getFilters(),
            sort: {
              name: this.companyPagination.sortBy,
              exp: this.companyPagination.descending ? 'DESC' : 'ASC',
            },
          })
          .then(
              (resolve) => {
                console.log('getCompanyListItems resolve', resolve)
                this.companyPagination.rowsNumber = resolve.pagination.getCnt()
                this.getCompanyListItems.forEach((company: any) => {
                  this.getCompanyUsersList(company)
                })
              },
              (reject) => {
                console.log('getCompanyListItems reject', reject)
              }
          )
    },

    //Загрузка пользователей компаний
    getCompanyUsersList(company: any){
      store
          .dispatch('getCompanyUsersListItems', {
            id: company.id
          })
          .then(
              (resolve) => {
                console.log('getCompanyUsersListItems resolve', resolve)
                resolve.items.forEach((user: any) => {
                  company.users.push(user.array[2])
                })
                this.tableKey++
                setTimeout(() => {
                  if(window.location.href.includes('?username')){
                    const link = new URL(window.location.href).searchParams.get('username')
                    this.getCompanyListItems.forEach((companyUnit: any,idx: any) => {
                      !companyUnit.users.includes(link) ? this.getCompanyListItems.splice(idx, 1) : ''
                      this.tableKey++
                    })
                  }
                }, 50)
              },
              (reject) => {
                console.log('getCompanyUsersListItems reject', reject)
              }
          )
    },

    //Поиск
    search() {
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.loadCompanyListItems()
    },

    //Очиска поиска
    clearSelect() {
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.selectedId = ''
      this.selectedName = ''
      this.loadCompanyListItems()
    },

  },

  created() {
    this.loadCompanyListItems()
  },


})

</script>

<style lang="scss">

.company-list{
  max-width: 1440px;
  margin: 0 auto;
  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
    }
    .q-btn{
      padding: 12px 24px 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }

  .q-table__top {
    padding: 20px !important;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    border-radius: 10px !important;
    .q-field{
      max-width: 215px;
      &__native{
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__control{
        border-radius: 5px;
      }
    }
    .q-field__native {
      min-width: 150px;
    }
    .q-field__inner {
      margin: 0 15px 0 0;
    }

    button{
      margin: 0 0 0 auto;
      height: 50px;
      width: 170px;
      border-radius: 10px;
      background: #3742fa !important;
      color: #fff;
      font-weight: 600;
      &:last-child{
        margin: 0 0 0 20px;
      }
    }
  }

  .q-table__middle{
    margin: 35px 0 0 0;
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
    .button{
      color: #027BE3;
      cursor: pointer;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  .q-card{
    padding: 0 !important;
  }

  .filter {
    margin: 0 0 0 25px;
    padding: 9px 15px;
    font-size: 15px;
  }
  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
    height: 56px;

  }
  .q-field--dense .q-field__label{
    font-size: 16px !important;
    top: 18px !important;
  }
}


</style>