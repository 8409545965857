<template>

  <div class="company-edit">
    <q-card
        class="q-pa-md"
    >
      <q-item>
        <q-card-section>
          <q-item-label class="text-h5"
          >Изменить компанию
          </q-item-label
          >
        </q-card-section>
      </q-item>
      <div v-if="!isCompanyListBusy">
        <q-form
            @submit="saveCompany"
            greedy
        >
          <q-card-section>
            <q-input
                v-model="item.name"
                label="Название"
                filled
                lazy-rules
                :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
            />
          </q-card-section>
          <q-card-actions style="display: flex;justify-content: space-between;padding: 16px">
            <q-btn color="positive" type="submit"> Сохранить</q-btn>
            <q-btn color="negative" @click="deleteCompany"> Удалить</q-btn>
            <q-btn color="primary" @click="closeCompanyEdit"> Отмена</q-btn>
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
    <q-dialog v-model="isActiveModal">
      <CompanyDelete
          v-if="isActiveModalDelete"
          :companyId="item.id"
          :companyName="item.name"
          @afterAction="this.$emit('afterAction')"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>


</template>

<script lang="ts">
/*import store from "@/store";*/
import {defineComponent} from 'vue'
import store from "@/store";
import CompanyDelete from './CompanyDelete.vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({

  components: {
    CompanyDelete
  },

  name: "CompanyEdit",

  props: {
    companyId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      router: useRouter(),
      route: useRoute(),
      item: {
        id: '',
        name: ''
      },
      isActiveModal: false,
      isActiveModalDelete: false
    }
  },

  computed: {
    isCompanyListBusy() {
      return store.getters.getIsCompanyListBusy
    }
  },

  methods: {

    //Закрытие модалки удаление компании
    hideModal() {
      this.isActiveModalDelete = false
      this.isActiveModal = false
    },

    //Активация модалки удаление компании
    deleteCompany() {
      this.isActiveModalDelete = true
      console.log('deleteCompany')
      this.isActiveModal = true
    },

    //Кнопка назад
    closeCompanyEdit(){
      this.router.go(-1)
    },

    //Сохранить изменения компании
    saveCompany() {
      let data = {
        id: this.item.id,
        name: this.item.name
      }

      store.dispatch('updateCompanyItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.router.push('/companies')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },

    //Загрузка данных о компании
    loadData() {
      store
          .dispatch('getCompanyListItem', {
            filter: [
              {
                field_name: 'company_id',
                operand: '=',
                value: this.route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCompanyId(),
                  name: resolve.getCompanyName()
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

  },
  created() {
    this.loadData()
  },

})
</script>

<style lang="scss">

.company-edit{
  .q-pa-md{
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
  }
  .tittle{
    padding: 0 16px;
    margin: 0;
    h3{
      font-size: 22px;
      line-height: 100%;
      margin: 0 0 16px 0;
    }
  }
  .q-card__actions{
    button:nth-child(2){
      margin: 0 0 0 auto;
    }
    button:nth-child(3){
      margin: 0 0 0 15px;
    }
  }
}

</style>