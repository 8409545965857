import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "statistics" }
const _hoisted_2 = { class: "statistics__body" }
const _hoisted_3 = { class: "statistics__body-screen" }
const _hoisted_4 = {
  key: 0,
  src: "https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif",
  alt: "loader"
}
const _hoisted_5 = {
  width: "900",
  height: "500",
  ref: "camera_canvas",
  id: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.loader)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("canvas", _hoisted_5, null, 512)
      ])
    ]),
    _createVNode(_component_q_table, {
      columns: _ctx.columns,
      rows: _ctx.getZoneListItems
    }, {
      "body-cell-type": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_q_list, null, {
              default: _withCtx(() => [
                (props.value)
                  ? (_openBlock(), _createBlock(_component_q_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createElementVNode("svg", {
                              version: "1.1",
                              id: "Layer_1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              x: "0px",
                              y: "0px",
                              width: "18px",
                              height: "18px",
                              viewBox: "0 0 96 96",
                              "enable-background": "new 0 0 96 96",
                              "xml:space": "preserve"
                            }, [
                              _createElementVNode("g", null, [
                                _createElementVNode("path", {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  fill: "#6BBE66",
                                  d: "M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"
                                })
                              ])
                            ], -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_q_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createElementVNode("svg", {
                              width: "18px",
                              height: "18px",
                              id: "Layer_1",
                              "data-name": "Layer 1",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 122.88 122.88"
                            }, [
                              _createElementVNode("defs"),
                              _createElementVNode("title", null, "cancel"),
                              _createElementVNode("path", {
                                style: {"fill":"#c00706","fill-rule":"evenodd"},
                                d: "M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"
                              }),
                              _createElementVNode("path", {
                                style: {"fill":"#fff","fill-rule":"evenodd"},
                                d: "M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"
                              })
                            ], -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "no-data": _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
      ])),
      _: 1
    }, 8, ["columns", "rows"])
  ]))
}