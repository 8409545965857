<template>

  <div class="company-watch">
    <div class="company-watch__header">
      <h3>{{ item.name }}</h3>
      <div class="company-watch__header-navigation">
        <q-btn v-if="userRole === 'super' || userRole === 'admin'" color="primary" @click="editCompany"> Изменить </q-btn>
      </div>
    </div>
    <div class="company-watch__left">
      <div class="company-watch__profile">
        <div class="company-watch__profile-header">
          <h3>Профиль</h3>
        </div>
        <div class="company-watch__profile-body">
          <ul>
            <li>
              <h3>Название:</h3>
              <p>{{ item.name }}</p>
            </li>
            <li>
              <h3>Дата добавления:</h3>
              <p>{{ item.created }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="company-watch__users">
        <div class="company-watch__users-header">
          <h3>Пользователи: {{ getCompanyUsersListItems.length }}</h3>
          <q-btn
              :disable="isCompanyUsersListBusy"
              color="white"
              text-color="black"
              label="Добавить"
              @click="addUser"
              v-if="userRole === 'super' || userRole === 'admin'"
          />
        </div>
        <div class="company-watch__users-body">
          <q-table
              flat
              style="height: 100%; overflow: auto;"
              :rows="getCompanyUsersListItems"
              :columns="usersColumns"
              row-key="id"
              :loading="isCompanyUsersListBusy"
          >
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item v-if="userRole === 'super' || userRole === 'admin'" clickable @click="actionRow('delete', props.value)">
                    <q-item-section>
                      <svg fill="#ff0000" version="1.1" id="Capa_1" width="18px" height="18px" viewBox="0 0 482.428 482.429" xml:space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path> <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path> <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path> </g> </g> </g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <div class="company-watch__right">
      <div class="company-watch__cameras">
        <div class="company-watch__cameras-header">
          <h3>Камеры: {{ getCamerasListItems.length }}</h3>
        </div>
        <div class="company-watch__cameras-body">
          <q-table
              flat
              :rows="getCamerasListItems"
              :columns="camerasColumns"
              row-key="id"
              :key="tableKey"
              :loading="isCamerasListBusy"
          >
            <template v-slot:body-cell-name="props">
              <q-td :props="props">
                <div class="options-block" v-if="props.row.name">
                  <p class="button" @click="watchCamera(props.row.id)">{{ props.row.name }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-status="props">
              <q-td :props="props">
                <div class="options-block status">
                  <div v-if="props.row.status === null" class="loadingio-spinner-rolling-n528f37fyyp">
                    <div class="ldio-17n7lgeict4">
                      <div>
                      </div>
                    </div>
                  </div>
                  <p class="error" v-if="props.row.status === false">
                    error
                  </p>
                  <p class="success" v-if="props.row.status === true">
                    success
                  </p>
                </div>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <q-dialog v-model="isActiveModal">
      <UserAdd
          v-if="isActiveModalAdd"
          :companyId="selectedCompanyId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
      <UserDelete
          v-if="isActiveModalDelete"
          :userId="selectedUserId"
          :companyId="selectedCompanyId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue'
import store from "@/store";
import UserAdd from "@/components/modules/company/views/company/CompanyUserLink.vue";
import UserDelete from "@/components/modules/company/views/company/CompanyUserUnlink.vue";
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: "CompanyWatch",

  components: {
    UserAdd,
    UserDelete,
    //DataLoading
  },

  data(){
    return{
      router: useRouter(),
      route: useRoute(),
      item: {
        id: 0,
        name: '',
        created: '',
        cameras_count: '',
        users_count: ''
      },
      camerasColumns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          headerStyle: 'font-weight: 500;font-size: 16px'
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 500;font-size: 16px'
        },
        {
          name: 'status',
          label: 'Статус',
          field: 'status',
          align: 'center',
          style: 'width: 102px;',
          headerStyle: 'font-weight: 500;font-size: 16px'
        },
        {
          name: 'type',
          label: 'Тип',
          field: 'type',
          align: 'left',
          headerStyle: 'font-weight: 500;font-size: 16px'
        },
      ],
      usersColumns: [
        {
          name: 'name',
          label: 'Имя',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 500;font-size: 16px'
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
          style: 'width: 40px',
        },
      ],
      tableKey: 0,
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedUserId: null as any,
      selectedCompanyId: null as any,
      userRole: localStorage.getItem('userRole')
    }
  },

  computed: {

    isCamerasListBusy() {
      return store.getters.getIsCamerasListBusy
    },

    isCompanyListBusy() {
      return store.getters.getIsCompanyListBusy
    },

    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },

    isCompanyUsersListBusy() {
      return store.getters.getIsCompanyUsersListBusy
    },

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getCompanyId(),
            name: item.getCompanyName(),
            cameras_count: item.getCompanyCamerasCount(),
            users_count: item.getCompanyUsersCount(),
            created: item.getCompanyCreated()
          })
        }
      }
      return rows
    },

    //Формирование списка камер
    getCamerasListItems() {
      const items = store.getters.getCamerasListItems
      let rows: any = []
      if (items !== undefined) {
        for (let camera of items) {
          rows.push({
            id: camera.getCameraId(),
            name: camera.getCameraName(),
            type: camera.getCameraType(),
            status: null
          })
        }
      }
      return rows
    },

    //Формирование списка компаний
    getCompanyUsersListItems(){
      const items = store.getters.getCompanyUsersListItems
      let rows: any = []
      if (items !== undefined) {
        console.log()
        for (let user of items) {
          rows.push({
            id: user.getUserId(),
            name: user.getFirstName() + ' ' + user.getLastName() + ' ' + user.getMiddleName()
          })
        }
      }
      return rows
    }

  },

  methods:  {

    //Просмотр камеры
    watchCamera(id: any){
      this.router.push('/cameras/watch/' + id)
    },

    //Изменение компании
    editCompany(){
      this.router.push('/companies/edit/' + this.route.params.id)
    },

    //Фильтр для камеры
    getCamerasFilters() {
      return [
        {
          field_name: 'camera_company_id',
          operand: '=',
          value: this.route.params.id,
        }
      ]
    },

    //Загрузка списка камер
    loadCamerasListItems() {
      store
          .dispatch('getCamerasListItems', {
            filter: this.getCamerasFilters(),
          })
          .then(
              (resolve) => {
                console.log('getCamerasListItems resolve', resolve)
                this.loadLastFrame()
              },
              (reject) => {
                console.log('getCamerasListItems reject', reject)
              }
          )
    },

    //Загрузка списка пользователей компании
    getCompanyUsersList(){
      store
          .dispatch('getCompanyUsersListItems', {
            id: this.route.params.id
          })
          .then(
              (resolve) => {
                console.log('getCompanyUsersListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCompanyUsersListItems reject', reject)
              }
          )
    },

    //Скрыть модалки
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedCompanyId = null
    },

    //Просмотр пользователя
    watchUser(){
      this.router.push('/users/watch/' + this.selectedUserId)
    },

    //Добавить пользователя в компанию
    addUser() {
      this.selectedCompanyId = this.item.id
      this.isActiveModalAdd = true
      console.log('addUser')
      this.isActiveModal = true
    },

    //Удалить пользователя из компании
    deleteUser() {
      this.selectedCompanyId = this.item.id
      this.isActiveModalDelete = true
      console.log('deleteUser')
      this.isActiveModal = true
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedUserId = id
      method === 'watch' ? this.watchUser() :
          method === 'delete' ? this.deleteUser() : console.log('Неизвестный метод')
    },

    //Прогрузка после взаимодействий с камерами
    afterAction() {
      console.log('afterAction')
      this.getCompanyUsersList()
      this.hideModal()
    },

    //Получение последнего кадра с камеры
    loadLastFrame(){
      this.getCamerasListItems.forEach((unit: any) => {
        store
            .dispatch('getItemCamera', { id: unit.id }).then(
            (resolve) => {
              console.log('loadData resolve', resolve)
              unit.status = true
              this.tableKey++
            },
            (reject) => {
              console.log('loadData reject', reject)
              unit.status = false
              this.tableKey++
            })
      })
    },

    //Загрузка данных компании
    loadData() {
      store
          .dispatch('getCompanyListItem', {
            filter: [
              {
                field_name: 'company_id',
                operand: '=',
                value: this.route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCompanyId(),
                  name: resolve.getCompanyName(),
                  created: resolve.getCompanyCreated(),
                  cameras_count: resolve.getCompanyCamerasCount(),
                  users_count: resolve.getCompanyUsersCount(),
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

  },

  created() {
    this.loadCamerasListItems()
    this.getCompanyUsersList()
    this.loadData()
  }

})
</script>

<style lang="scss">

.company-watch{
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
      margin: 0;
    }
    .q-btn{
      padding: 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }
  &__left,&__right{
    width: calc(50% - 10px);
  }
  &__profile,&__users,&__cameras{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &-header{
      background: #3742fa;
      border-bottom: 1px solid #b3b3b3;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 20px;
      border-radius: 10px 10px 0 0;
      height: 77px;
      h3{
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        color: #fff;
      }
    }
    .q-table__container{
      border-radius: 0 0 10px 10px;
      box-shadow: unset;
    }
  }
  &__profile {
    &-body {
      ul {
        padding: 18px 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
          display: flex;
          align-items: flex-start;
          list-style: none;
          width: 100%;
          &:not(:last-child), &:not(:first-child) {
            padding: 15px 0;
          }

          h3 {
            width: 160px;
            text-align: left;
            flex-shrink: 0;
          }

          h3, p {
            font-size: 16px;
            font-weight: 700;
            padding: 0;
            margin: 0;
            line-height: 100%;
          }

          p {
            font-weight: 400;
            padding: 0 0 0 15px;
          }
        }
      }
    }
  }
  &__users{
    margin: 20px 0 0 0;
    &-header{
      justify-content: space-between;
    }
    table{
      thead{
        tr{
          height: 0;
        }
      }
    }
  }
  .options-block{
    p{
      margin: 0;
    }
    .success,.error{
      display: flex;
      width: 70px;
      flex-shrink: 0;
      justify-content: center;
      background: #21BA45;
      color: #fff;
      padding: 0 5px 1px 5px;
      margin: -3px 0 0 0;
      border-radius: 6px;
    }
    .error{
      background: #c00706;
    }
  }
  .button{
    color: #027BE3;
    cursor: pointer;
  }
  .status{
    @keyframes ldio-17n7lgeict4 {
      0% { transform: translate(-50%,-50%) rotate(0deg); }
      100% { transform: translate(-50%,-50%) rotate(360deg); }
    }
    .ldio-17n7lgeict4 div {
      position: absolute;
      width: 70px;
      height: 70px;
      border: 10px solid #0a0a0a;
      border-top-color: transparent;
      border-radius: 50%;
    }
    .ldio-17n7lgeict4 div {
      animation: ldio-17n7lgeict4 1s linear infinite;
      top: 50px;
      left: 50px
    }
    .loadingio-spinner-rolling-n528f37fyyp {
      width: 20px;
      height: 20px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }
    .ldio-17n7lgeict4 {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(0.2);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-17n7lgeict4 div { box-sizing: content-box; }
  }
}

</style>